import { useLocale } from '@ecomm/data-hooks'
import { useFocusTrap, useLockBodyScroll } from '@ecomm/shared-hooks'
import { document } from 'browser-monads-ts'
import classNames from 'classnames'
import { some } from 'fp-ts/lib/Option'
import { useEffect, useRef, useState } from 'react'

import { useCallOnEvent } from '../../hooks/useCallOnEvent'
import { NavLink } from './NavLink'
import { NavMenu } from './NavMenu'
import {
  NARROWEST_DESKTOP_SIZE,
  getCourtesyNavItems,
  getMainMenuItems
} from './data'
import { HeaderRedesignSchema } from './schema'

type SideNavProps = HeaderRedesignSchema & {
  readonly show: boolean
  readonly toggle: (track?: boolean) => void
  readonly close: () => void
  readonly top: number
}

export function SideNav({
  show,
  toggle,
  top,
  images,
  close,
  quoteWizard
}: SideNavProps) {
  const locale = useLocale()

  const ref = useRef(null)

  /* This value will be used to calculate the sidenav's height. We could use 100vh in its place but since mobile browsers
   won't give the expected value for that, we replace it with document.documentElement.clientHeight instead. It needs to be
   in the state to trigger a rerender if that value changes when resizing the window (for instance while zooming in and out)
   We initialize with 0 first. And then we update it with document.documentElement.clientHeight inside an useEffect. Why is this?
   Because document.documentElement.clientHeight is a value that is only available at runtime. By setting it to a state variable inside
   an useEffect, we can be sure it will be set after hydration is complete and therefore document.documentElement.clientHeight has a valid value.
  */
  const [clientHeight, setClientHeight] = useState(0)
  useEffect(() => {
    setClientHeight(document.documentElement.clientHeight)
  }, [])

  useFocusTrap(ref, show)

  useCallOnEvent(() => {
    setClientHeight(document.documentElement.clientHeight)
    // Close side nav, when resizing to desktop mode, to unlock the body scroll
    document.body.clientWidth >= NARROWEST_DESKTOP_SIZE && close()
  }, 'resize')

  useLockBodyScroll(show, false)

  const [activeMainMenuDropdownIndex, setActiveMainMenuDropdownIndex] =
    useState(-1)
  const [activeCourtesyNavDropdownIndex, setActiveCourtesyNavDropdownIndex] =
    useState(-1)

  return (
    <>
      <nav
        aria-label="side navigation"
        className={classNames(
          'absolute left-0 top-[50px] z-20 flex h-screen w-9/12 flex-col bg-white transition-all duration-300 md:w-6/12 min-[1126px]:hidden',
          {
            'invisible -translate-x-full md:-translate-x-[442px]': !show,
            'visible translate-x-0': show
          }
        )}
        ref={ref}
      >
        <div
          className="flex w-full flex-col flex-nowrap justify-between overflow-y-auto"
          style={{
            height: `calc(${clientHeight}px + ${document.documentElement.scrollTop}px - ${top}px - 50px)`
          }}
        >
          <div>
            {getMainMenuItems(locale).map((item, i) =>
              item.hasDropdown ? (
                <NavMenu
                  dropdownMenu={item.dropdownMenu}
                  images={images}
                  key={i}
                  mobileIcon={item.mobileIcon}
                  onToggleDropdown={(close?: boolean) => {
                    close && toggle()
                    !close &&
                      setActiveMainMenuDropdownIndex(
                        activeMainMenuDropdownIndex === i ? -1 : i
                      )
                    setActiveCourtesyNavDropdownIndex(-1)
                  }}
                  quoteWizard={quoteWizard}
                  showDropdown={activeMainMenuDropdownIndex === i}
                  showOverlay={false}
                  sideNav={true}
                  text={item.text}
                />
              ) : (
                <NavLink
                  key={i}
                  mobileIcon={item.mobileIcon}
                  openQuiz={item.openQuiz}
                  quoteWizard={{ ...quoteWizard }}
                  text={item.text}
                  toggleSideNav={some(toggle)}
                  url={item.url}
                />
              )
            )}
          </div>
          <div className="bg-neutral-black w-full">
            {getCourtesyNavItems(locale)
              .filter(item => item.displayInSideNav)
              .map((item, i) =>
                item.hasDropdown ? (
                  <NavMenu
                    displayInSideNav={item.displayInSideNav}
                    dropdownMenu={item.dropdownMenu}
                    images={images}
                    key={i}
                    onToggleDropdown={(close?: boolean) => {
                      close && toggle()
                      !close &&
                        setActiveCourtesyNavDropdownIndex(
                          activeCourtesyNavDropdownIndex === i ? -1 : i
                        )
                      setActiveMainMenuDropdownIndex(-1)
                    }}
                    quoteWizard={quoteWizard}
                    showDropdown={activeCourtesyNavDropdownIndex === i}
                    showOverlay={false}
                    sideNav={true}
                    text={item.text}
                  />
                ) : (
                  <NavLink
                    className="h-16 w-full gap-3 !border-b-neutral-300 px-4 text-lg font-medium text-white min-[1126px]:p-0"
                    key={i}
                    quoteWizard={{ ...quoteWizard }}
                    text={item.text}
                    toggleSideNav={some(toggle)}
                    url={item.url}
                  />
                )
              )}
          </div>
        </div>
      </nav>
      <div
        className={classNames(
          'absolute left-0 top-[50px] z-[9] h-screen w-full bg-black transition-opacity duration-300 min-[1126px]:hidden',
          {
            'opacity-40': show,
            'pointer-events-none opacity-0': !show
          }
        )}
        data-testid="sidenav-background"
        onClick={() => toggle(true)}
      />
    </>
  )
}
