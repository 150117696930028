import type { footerSchema } from '@ecomm/footer-data'
import type { TypeOf } from '@simplisafe/ewok'

export const checkoutFooterData: TypeOf<typeof footerSchema> = {
  copyrightText: '© 2022 SimpliSafe, Inc.',
  legalLinksCollection: {
    items: [
      {
        text: 'Legal',
        url: '/legal',
        trackingEvent: null
      },
      {
        text: 'Privacy Policy',
        url: '/privacy-policy',
        trackingEvent: 'link-click-privacy-footer'
      },
      {
        text: 'Help Center',
        url: 'https://support.simplisafe.com/hc/en-us',
        trackingEvent: null
      }
    ]
  },
  menusCollection: { items: [] },
  socialLinksCollection: { items: [] }
}
