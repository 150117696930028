import { trackClickBuildASystemCta } from '@ecomm/cdp-tracking'
import { usePriceContext } from '@ecomm/data-price'
import {
  type GatsbyImageSchema,
  GatsbyImage,
  QuantitySelector
} from '@ecomm/shared-components'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { Link } from 'gatsby'
import { useCallback, useState } from 'react'

import AddToCartButton from '../AddToCartButton'
import { ColorSelectorSlim } from '../ColorSelector'
import type { ColorVariantSchema } from '../ColorSelector/schema'

export type RiskFreeProps = {
  readonly product: {
    readonly maxQuantity: number
    readonly hideBmsButton: boolean
  }
  readonly productImage: GatsbyImageSchema
  readonly productImageVariant?: GatsbyImageSchema | null
  readonly copy: string
  readonly quantity?: number
  readonly sku: string
  readonly variant?: readonly ColorVariantSchema[]
}

export function RiskFree({
  product,
  productImage,
  productImageVariant,
  copy,
  quantity = 1,
  sku: defaultSku,
  variant
}: RiskFreeProps): JSX.Element {
  const { getFormattedPrice } = usePriceContext()

  const [_quantity, setQuantity] = useState(quantity)
  const [image, setImage] = useState(productImage)
  const [sku, setsku] = useState(defaultSku)

  const price = pipe(
    getFormattedPrice(defaultSku),
    O.getOrElse(() => '')
  )

  const handleChange = useCallback(
    (increase: boolean) => {
      increase ? setQuantity(_quantity + 1) : setQuantity(_quantity - 1)
    },
    [_quantity]
  )

  const handleChangeColor = (variantSku: string) => {
    const isMainColor = variantSku === defaultSku
    isMainColor
      ? setImage(productImage)
      : setImage(productImageVariant ?? productImage)
    return setsku(variantSku)
  }

  return (
    <div
      className="lg:align-stretch flex w-full flex-col lg:flex-row"
      data-component="RiskFreeComponent"
    >
      <div className="min-h-2/4 rounded-t-base bg-neutral-light-50 lg:rounded-l-base w-full lg:h-auto lg:w-2/5 lg:rounded-tr-none">
        <GatsbyImage
          className="rounded-tl-base rounded-tr-base lg:rounded-bl-base lg:rounded-tl-base w-full object-contain object-center md:h-auto lg:h-full lg:rounded-tr-none"
          image={image}
        />
      </div>
      <div className="md:flex-start md:align-start rounded-b-base bg-neutral-light-50 lg:rounded-e-base flex h-auto flex-col p-6 md:justify-center lg:h-auto lg:w-3/5 lg:rounded-bl-none lg:p-12">
        <h2 className="m-0 text-2xl font-medium md:text-5xl">
          Try it risk free
        </h2>
        <p className="text-base font-light md:text-lg md:leading-6 mt-4">
          {copy}
        </p>
        {variant?.length ? (
          <ColorSelectorSlim
            data={variant}
            defaultSku={sku}
            onChangeColor={sku => handleChangeColor(sku)}
            placement="RiskFree"
          />
        ) : null}
        <div
          className="justify-left mb-4 flex flex-row items-center"
          data-component="QuantitySelectorWrapper"
        >
          <span className="mr-4 text-xl md:text-2xl" data-component="Price">
            {price}
          </span>
          <QuantitySelector
            maxQuantity={product.maxQuantity}
            minQuantity={1}
            name={''}
            onDecrease={() => handleChange(false)}
            onIncrease={() => handleChange(true)}
            quantity={_quantity}
          />
        </div>
        <div className="flex flex-col md:mt-4 md:h-14 md:flex-row">
          <AddToCartButton.Product
            className="mb-4 w-full md:w-64"
            quantity={_quantity}
            sku={sku}
          />
          {!product.hideBmsButton ? (
            <Link to="/build-my-system">
              <button
                className="btn btn-outlined btn-outlined-primary mb-4 w-full md:w-64"
                onClick={trackClickBuildASystemCta}
                type="button"
              >
                Build a system
              </button>
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  )
}
