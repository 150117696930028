import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { jsonSchema } from '@ecomm/micro-copy'
import { guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const nhwWrappedSchema = z.object({
  quoteWizardAsset: gatsbyImageSchema,
  appFeaturesCarouselAssets: z.object({
    nodes: gatsbyImageSchema.extend({ contentful_id: z.string() }).array()
  }),
  expertReviewsAssets: z.object({
    nodes: gatsbyImageSchema.extend({ contentful_id: z.string() }).array()
  }),
  guaranteeAsset: gatsbyImageSchema,
  quoteWizard: guidedSystemBuilderSchema,
  guaranteeCopy: z.object({
    contentful_id: z.string(),
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  }),
  expertReviews: jsonSchema,
  expertSectionAsset: gatsbyImageSchema,
  comparisonTable: jsonSchema,
  nhwLogo: gatsbyImageSchema,
  nhwDescription: z.object({
    contentful_id: z.string(),
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  }),
  ceoComment: z.object({
    contentful_id: z.string(),
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  })
})

export type NhwWrappedSchema = TypeOf<typeof nhwWrappedSchema>
