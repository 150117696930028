import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import { Header } from '@ecomm/header-redesigned'
import {
  ApplyPromoCode,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import type { PageProps } from 'gatsby'

import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import type { SeoNodeSchema } from '../../schemas/seo'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly iframeUrl: string
  readonly path: string
  readonly title: string
  readonly footerId: string
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function IframePageTemplate<
  T extends { readonly footer: U },
  U
>({
  path,
  pageContext: { locale, seoDetails, iframeUrl, title, footerId }
}: Props<T>) {
  const footer = useFooterQuery(footerId)
  const headerData = useHeaderRedesignQuery()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={path ?? ''}>
      <SEO
        canonicalLink={seoDetails.canonicalLink ?? ''}
        isNofollow={seoDetails.isNofollow}
        isNoindex={seoDetails.isNoindex}
        lang={locale}
        metaDescription={seoDetails.metaDescription?.metaDescription ?? ''}
        metaKeywords={seoDetails.metaKeywords ?? []}
        metaTitle={seoDetails.metaTitle}
      />
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <ApplyPromoCode />
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={'standard'}
        />
        <Header {...headerData} />
        <div className="max-w-8xl mx-auto my-16 w-full px-4 md:px-8">
          <h1 className="text-heading-2-size w-full">{title}</h1>
          <div className="flex w-full flex-col gap-4 md:gap-8 lg:gap-12">
            <iframe
              aria-label={title}
              frameBorder="0"
              height={'830px'}
              src={iframeUrl}
              width="100%"
            />
          </div>
        </div>
        <Footer footerId={footerId} type={footer.type} />
      </div>
    </TrackingProvider>
  )
}
