import { useMicroCopy } from '@ecomm/micro-copy'
import React from 'react'

type Props = {
  readonly setOpen: () => void
}

export default function Button({ setOpen }: Props) {
  const microCopy = useMicroCopy()

  return (
    <div className="bg-neutral-black absolute bottom-0 left-0 z-[2] rounded-bl-base px-8 py-6 opacity-50">
      <p className="mb-2 mt-0 text-lg text-white">
        {microCopy['need-more-sensors']}
      </p>
      <button
        className="cursor-pointer border-0 bg-inherit p-0 text-base text-white underline hover:no-underline"
        onClick={setOpen}
      >
        {microCopy['personalize-your-package']}
      </button>
    </div>
  )
}
