import { useMediaQuery } from '@ecomm/shared-hooks'

import Carousel from './Carousel'
import Review, { type ReviewProps } from './Review'

export type ExpertsReviewsProps = {
  readonly reviews: readonly (ReviewProps & { readonly key: string })[]
}

export function ExpertsReviews({ reviews }: ExpertsReviewsProps) {
  const isMobile = !useMediaQuery('TabletAndUp')

  return (
    <div data-component="ExpertsReviews">
      {isMobile ? (
        <Carousel reviews={reviews} />
      ) : (
        <div className="md:max-w-8xl flex w-full snap-x snap-mandatory gap-4 overflow-scroll md:justify-center md:overflow-hidden lg:gap-8">
          {reviews.map(({ key, ...review }) => {
            return <Review {...review} key={key} />
          })}
        </div>
      )}
    </div>
  )
}
