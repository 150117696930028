import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import { Header } from '@ecomm/header-redesigned'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import {
  type FormQuerySchema,
  CountryRedirectModal,
  toPromoBannerStyleValue
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { match } from 'ts-pattern'

import { BreadcrumbsWrapper } from '../../components/BreadcrumbsWrapper'
import HomeLandingHeroBanner from '../../components/HomeLandingHeroBanner'
import type { SeoNodeSchema } from '../../config/homeLandingResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import { GatsbyGrid } from './GatsbyGrid'
import { usePartnerWithUsPageFragment } from './usePartnerWithUsPageFragment'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function PartnerWithUsPageTemplate<
  T extends { readonly contentfulPartnerWithUsLandingPage: U },
  U
>({ data, pageContext: { locale, seoDetails } }: Props<T>) {
  const { layout, slug, breadcrumbTitle } = usePartnerWithUsPageFragment(data)
  const {
    canonicalLink,
    isNofollow,
    isNoindex,
    metaDescription,
    metaKeywords,
    metaTitle
  } = seoDetails

  const {
    components,
    promoBannerStyle,
    footer: { contentful_id: footerId }
  } = layout
  const contentfulFormV2 = components?.find(
    component => component.__typename === 'ContentfulFormV2'
  ) as FormQuerySchema | undefined

  const headerData = useHeaderRedesignQuery()
  const promoBannerExperiment = usePromoBannerExperimentQuery()

  const bannerStyle = toPromoBannerStyleValue(promoBannerStyle) || 'none'

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <div className="relative lg:z-20">
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={bannerStyle}
        />
      </div>
      <Header {...headerData} />
      <BreadcrumbsWrapper breadcrumbTitle={breadcrumbTitle} slug={slug} />
      <main
        className="max-w-8xl prose md:prose-md lg:prose-lg mx-auto flex flex-col px-4 py-6 lg:px-8 lg:py-10"
        key="partner-with-us-page"
      >
        {components?.map((component, index) =>
          match(component)
            .with({ __typename: 'ContentfulHeroBanner' }, data => (
              <HomeLandingHeroBanner
                key={'partner-with-us-hero-banner'}
                {...data}
              />
            ))
            .with({ __typename: 'ContentfulGrid' }, ({ contentful_id }) => (
              <GatsbyGrid
                formV2Data={contentfulFormV2}
                id={contentful_id}
                key={`partner-with-us-grid-${index}`}
              />
            ))
            .otherwise(() => null)
        )}
        <CountryRedirectModal />
      </main>
      <Footer footerId={footerId} type="Full" />
    </TrackingProvider>
  )
}

export const query = graphql`
  query PartnerWithUsPageTemplate($id: String) {
    contentfulPartnerWithUsLandingPage(id: { eq: $id }) {
      ...partnerWithUsPageFragment
    }
  }
`
