import { GuidedSystemBuilderSchema } from '@ecomm/shared-components'
import classNames from 'classnames'
import { useState } from 'react'

import { CartLink } from './CartLink'
import { NavLink } from './NavLink'
import { NavMenu } from './NavMenu'
import { CourtesyNavItemType, cartItem } from './data'
import { HeaderRedesignSchema } from './schema'

type CourtesyNavProps = HeaderRedesignSchema & {
  readonly items: readonly CourtesyNavItemType[]
  readonly cartQuantity: number
  readonly quoteWizard: GuidedSystemBuilderSchema
}

export function CourtesyNav({
  items,
  images,
  cartQuantity,
  quoteWizard
}: CourtesyNavProps) {
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(-1)

  return (
    <div className="flex h-full items-center gap-1 text-white min-[1126px]:ml-auto min-[1126px]:gap-7">
      {items.map((item, i) =>
        item.hasDropdown ? (
          <div className="hidden min-[1126px]:block" key={i}>
            <NavMenu
              displayInSideNav={item.displayInSideNav}
              dropdownMenu={item.dropdownMenu}
              icon={item.icon}
              images={images}
              onToggleDropdown={(close?: boolean) =>
                setActiveDropdownIndex(
                  close ? -1 : activeDropdownIndex === i ? -1 : i
                )
              }
              quoteWizard={quoteWizard}
              showDropdown={activeDropdownIndex === i}
              showOverlay={false}
              text={item.text}
            />
          </div>
        ) : (
          <div
            className={classNames(
              items.filter(item => !item.hasDropdown).length > 1 &&
                '[&:not(:last-of-type)]:mr-2'
            )}
            key={i}
          >
            {item.url === cartItem.url ? (
              <CartLink
                cartQuantity={cartQuantity}
                icon={item.icon}
                text={item.text}
                url={item.url}
              />
            ) : (
              <NavLink
                displayInSideNav={item.displayInSideNav}
                icon={item.icon}
                mobileOnly={item.mobileOnly}
                openQuiz={item.openQuiz}
                quoteWizard={quoteWizard}
                text={item.text}
                url={item.url}
              />
            )}
          </div>
        )
      )}
    </div>
  )
}
