import { z } from '@simplisafe/ewok'

import { emailFeedbackPropsSchema } from '../../components/EmailFeedbackForm/schema'

const layoutSchema = z.object({
  promoBannerStyle: z.string(),
  components: emailFeedbackPropsSchema.array(),
  footer: z.object({
    contentful_id: z.string()
  })
})

export const emailFeedbackPageSchema = z.object({
  contentful_id: z.string(),
  layout: layoutSchema
})

export const parseEmailFeedbackPage = <
  T extends { readonly contentfulGenericLandingPage: U },
  U
>(
  query: T
) => {
  const emailPageFragment = query.contentfulGenericLandingPage

  const homeLandingPage = emailFeedbackPageSchema.parse(emailPageFragment)

  return homeLandingPage
}
