import { useLocale } from '@ecomm/data-hooks'
import { useState } from 'react'

import { NavLink } from './NavLink'
import { NavMenu } from './NavMenu'
import { getMainMenuItems } from './data'
import { HeaderRedesignSchema } from './schema'

export function MainNav(props: HeaderRedesignSchema) {
  const locale = useLocale()

  const [activeDropdownIndex, setActiveDropdownIndex] = useState(-1)

  return (
    <div className="hidden h-full items-center justify-between gap-6 justify-self-start text-lg min-[1126px]:flex min-[1185px]:gap-10">
      {getMainMenuItems(locale).map((item, i) =>
        item.hasDropdown ? (
          <NavMenu
            dropdownMenu={item.dropdownMenu}
            key={i}
            mainNavDesktop={true}
            onToggleDropdown={(close?: boolean) =>
              setActiveDropdownIndex(
                close ? -1 : activeDropdownIndex === i ? -1 : i
              )
            }
            showDropdown={activeDropdownIndex === i}
            text={item.text}
            {...props}
          />
        ) : (
          <NavLink
            key={i}
            openQuiz={item.openQuiz}
            quoteWizard={{ ...props.quoteWizard }}
            text={item.text}
            url={item.url}
          />
        )
      )}
    </div>
  )
}
