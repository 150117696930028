import { useState } from 'react'

import { VerifyPassFormSchema } from './schema'

//These for now are hardoded since there is not any field related to the option details for select options in contentful
const optionsMap = [
  {
    note: 'Including Active Duty, Veterans & Dependents',
    btnClass: 'vfyps_mil'
  },
  {
    note: 'Including Doctors, Nurses & Medical Staff',
    btnClass: 'vfyps_med'
  },
  {
    note: 'Including Pre-K, K-12 & Professors',
    btnClass: 'vfyps_tea'
  },
  {
    note: 'Including Police, Fire & EMT',
    btnClass: 'vfyps_fir'
  }
]

export default function VerifyDropdownForm({
  button: { text },
  form
}: VerifyPassFormSchema) {
  const [selectedOption, setSelectedOption] = useState(0)

  return (
    <>
      {form.map(field => (
        <div
          className="flex flex-col text-white"
          key={`field-${field.inputName}`}
        >
          <label>{field.title}</label>
          <div className="flex flex-col md:flex-row gap-6 md:gap-4">
            <div className="flex flex-col">
              <select
                aria-label="Select an option"
                className="h-14 rounded-base font-default text-body-size px-4"
                onChange={ev => setSelectedOption(+ev.target.value)}
                value={selectedOption}
              >
                <optgroup>
                  {field.options.map((opt, i) => (
                    <option id={opt} key={`opt-${opt}`} value={i}>
                      {opt}
                    </option>
                  ))}
                </optgroup>
              </select>
              <span>{optionsMap[selectedOption].note}</span>
            </div>
            <button
              className={`btn h-14 btn-solid-primary ${optionsMap[selectedOption].btnClass}`}
            >
              {text}
            </button>
          </div>
        </div>
      ))}
    </>
  )
}
