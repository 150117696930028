import { ButtonFragment } from '@ecomm/shared-components'
import { Link } from 'gatsby'
import React from 'react'

function HomeLandingButton({ url, buttonText }: ButtonFragment) {
  return (
    <div className="mx-auto" data-component={HomeLandingButton.name}>
      <Link
        className="btn btn-solid-primary no-underline inline-block"
        to={url}
      >
        {buttonText}
      </Link>
    </div>
  )
}

export default HomeLandingButton
