import {
  SELF_MONITORING_WITH_CAMERA_RECORDINGS,
  STANDARD_MONITORING
} from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { PriceProvider } from '@ecomm/data-price'
import {
  BottomTextSection,
  ExpandableMonitoring,
  OdmonExpandableMonitoring
} from '@ecomm/monitoring/components'
import { AlarmMoments, FAQSection } from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import {
  AppWidget,
  FeatureSection,
  GatsbySmallText
} from '@ecomm/shared-sections'
import { useLocation } from '@reach/router'
import React from 'react'
import { match } from 'ts-pattern'
import { literal } from 'zod'

import { ConfidenceBar } from '../components/ConfidenceBar'
import type {
  ComponentTypes,
  ContentExperienceSchema
} from '../components/ContentMapper/schema'
import MonitoringHardcodedComponent from '../components/MonitoringHardcodedComponent'
import { Stepper } from '../components/Stepper'
import VideoSection from '../components/VideoSection'
import { hasPackageSku } from './helpers'
import { useConfirmMonitoringPlanQuery } from './useConfirmMonitoringPlanQuery'

export const getMappedComponent = (
  component: ComponentTypes,
  index: number
) => {
  return match(component)
    .with({ __typename: literal('ContentfulGrid').value }, data => (
      <ConfidenceBar
        items={data.gridItems}
        key={`grid-section-${index}`}
        {...data}
      />
    ))
    .with(
      { __typename: literal('ContentfulSmallText').value },
      ({ contentful_id }) => (
        <GatsbySmallText
          id={contentful_id}
          key={`small-text-section-${index}`}
        />
      )
    )
    .with({ __typename: literal('ContentfulVideo').value }, data => (
      <VideoSection key={`video-section-${index}`} {...data} />
    ))
    .with({ __typename: literal('ContentfulAppWidget').value }, data => (
      <AppWidget data={data} key={`appwidget-section-${index}`} />
    ))
    .with({ __typename: literal('ContentfulAlarmMoments').value }, data => (
      <AlarmMoments {...data} key={`alarmMoments-section-${index}`} />
    ))
    .with({ __typename: literal('ContentfulFaq').value }, data => (
      <div
        className="prose-h2:mb-6 prose-h2:text-center prose-h2:md:mb-8"
        data-component="FAQSection"
      >
        <FAQSection faq={data} title={data.title} />
      </div>
    ))
    .with({ __typename: literal('ContentfulPlaceholder').value }, data => (
      <MonitoringHardcodedComponent label={data.label} />
    ))
    .with(
      { __typename: literal('ContentfulMonitoringPlanComparison').value },
      data => {
        const locale = useLocale()
        const location = useLocation()
        const packageSku = hasPackageSku(location.state)
          ? location.state.packageSku
          : ''

        //Need to revisit this logic. Maybe set something in contentful to receive the standard monitoring sku from there instead of hardcoding it.
        const standardPlans = locale === 'en-US' ? [STANDARD_MONITORING] : []
        const planConfirmationModal = useConfirmMonitoringPlanQuery()
        return (
          <PriceProvider
            locale={locale}
            skus={[
              ...standardPlans,
              ...data.monitoringCards.map(m => m.sku),
              packageSku
            ]}
          >
            <ExpandableMonitoring
              {...data}
              packageSku={packageSku}
              planConfirmationModal={planConfirmationModal}
            />
          </PriceProvider>
        )
      }
    )
    .with(
      { __typename: literal('ContentfulOdmonMonitoringPlanComparison').value },
      data => {
        const locale = useLocale()
        //Need to revisit this logic. Maybe set something in contentful to receive the standard monitoring sku from there instead of hardcoding it.
        const standardPlans =
          locale === 'en-US'
            ? [STANDARD_MONITORING, SELF_MONITORING_WITH_CAMERA_RECORDINGS]
            : []

        return (
          <PriceProvider
            locale={locale}
            skus={[...data.monitoringCards.map(m => m.sku), ...standardPlans]}
          >
            <OdmonExpandableMonitoring {...data} />
          </PriceProvider>
        )
      }
    )
    .with({ __typename: literal('ContentfulFeatureSection').value }, data => (
      <FeatureSection
        columnClassName="first:order-1 last:order -my-10 lg:-my-16"
        {...data}
      />
    ))
    .with({ __typename: literal('ContentfulJson').value }, data => {
      return (
        <BottomTextSection
          textElements={JSON.parse(data.content.internal.content)}
        />
      )
    })
    .with({ __typename: literal('ContentfulStepper').value }, data => (
      <Stepper {...data} />
    ))
    .with(
      { __typename: literal('ContentfulMonitoringPageContent').value },
      contentProps => {
        const locale = useLocale()
        return locale === 'en-US' ? (
          <Experience
            {...contentProps}
            component={({
              inside: components
            }: { readonly inside: readonly ComponentTypes[] }) => {
              return components.map((componentData, index) =>
                getMappedComponent(componentData, index)
              )
            }}
            experiences={mapExperiences<ContentExperienceSchema>(
              contentProps.nt_experiences
            )}
            key={contentProps.id}
          />
        ) : (
          <div>
            {contentProps.inside.map((componentData, index) =>
              getMappedComponent(componentData, index)
            )}
          </div>
        )
      }
    )
    .otherwise(() => null)
}
