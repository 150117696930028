import { voidFn } from '@simplisafe/ewok'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'

import CompanyLogos from './CompanyLogos'
import { ReviewSectionFragment } from './schema'
import Testimonial from './Testimonial'

export type ReviewSectionProps = {
  readonly data: ReviewSectionFragment
}

export const AUTOPLAY_SPEED = 5000

const ungroupTestimonialsAndLogos = (
  reviews: ReviewSectionFragment['reviews']
) => {
  const testimonials = reviews?.map(({ review }) => ({ raw: review.raw }))
  const companyLogos = reviews?.map(({ company, logo }) => ({
    company,
    logo
  }))

  return {
    companyLogos,
    testimonials
  }
}

function ReviewSection({ data }: ReviewSectionProps) {
  const { reviews, button } = data
  const { testimonials, companyLogos } = ungroupTestimonialsAndLogos(reviews)

  const [activeReviewIndex, setActiveReviewIndex] = useState<number>(0)
  const [hoveredReviewIndex, setHoveredReviewIndex] = useState<number | null>(
    null
  )
  const visibleTestimonial =
    testimonials && testimonials[activeReviewIndex || 0]

  useEffect(
    function handleAutoPlay() {
      if (!hoveredReviewIndex && !!testimonials?.length) {
        const interval = setInterval(() => {
          setActiveReviewIndex(prev => (prev + 1) % testimonials.length)
        }, AUTOPLAY_SPEED)

        return () => clearInterval(interval)
      } else {
        return voidFn()
      }
    },
    [activeReviewIndex, hoveredReviewIndex, testimonials?.length]
  )

  return (
    <div className="rounded-base bg-neutral-light-100 flex flex-col items-center justify-center overflow-hidden p-8 md:p-8 lg:p-16">
      {visibleTestimonial ? (
        <Testimonial testimonial={visibleTestimonial} />
      ) : null}
      {companyLogos ? (
        <CompanyLogos
          activeReviewIndex={hoveredReviewIndex || activeReviewIndex}
          logos={companyLogos}
          setActiveReviewIndex={setActiveReviewIndex}
          setHoveredReviewIndex={setHoveredReviewIndex}
        />
      ) : null}
      {button ? (
        <div className="w-full py-8 text-center">
          <Link
            className="btn btn-solid-primary text-base h-12 inline-flex items-center"
            key="link"
            to={button.url}
          >
            {button.text}
          </Link>
        </div>
      ) : null}
    </div>
  )
}

export default ReviewSection
