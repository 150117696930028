import { Link } from 'gatsby'
import React, { useCallback } from 'react'
import { useTracking } from 'react-tracking'

export const REFURBISHED_EVENT_NAME = 'shop-refurb-see-details'
export const NON_REFURBISHED_EVENT_NAME = 'shop-see-details'

type Props = {
  readonly slug: string
  readonly className?: string
  readonly product?: string
  readonly onClick?: () => void
}

export default function DetailsButton({
  slug,
  product,
  className = 'font-normal border-[0.125rem] px-6 py-3',
  onClick: onClickProp
}: Props) {
  const { trackEvent } = useTracking()
  const onClick = useCallback(() => {
    const isRefurbished = slug.includes('refurb')
    const eventName = isRefurbished
      ? REFURBISHED_EVENT_NAME
      : NON_REFURBISHED_EVENT_NAME
    trackEvent({
      package: product,
      event: eventName
    })
  }, [slug])

  return (
    <Link
      className={`whitespace-nowrap no-underline btn btn-solid-primary inline-block w-full md:w-fit ${className}`}
      data-testid="package-see-details-button"
      onClick={onClickProp || onClick}
      to={`/${slug}`}
    >
      See details
    </Link>
  )
}
