import {
  AFFIRM_TERM_MONTHS_SYSTEM,
  AFFIRM_TERM_MONTHS_SYSTEM_VARIANT
} from '@ecomm/data-constants'
import classNames from 'classnames'
import React from 'react'

import { AffirmInformationLogoIcon } from '../../../AffirmInformationLogoIcon'
import type { AffirmOptionType } from '../../../types/affirm'

type Props = {
  readonly affirmOption: AffirmOptionType
  readonly onClick: () => void
  readonly priceInCents: number
  readonly isPlp?: boolean
  readonly useSystemTermMonths?: boolean
  readonly isAffirmExperience?: boolean
}

export function MonthlyOptionSubText({
  affirmOption,
  onClick,
  priceInCents,
  isPlp = false,
  useSystemTermMonths = true,
  isAffirmExperience = false
}: Props) {
  const months = isAffirmExperience
    ? AFFIRM_TERM_MONTHS_SYSTEM_VARIANT
    : AFFIRM_TERM_MONTHS_SYSTEM

  return affirmOption === 'qualified-monthly' ? (
    <p
      className={classNames('my-1 text-xs font-normal leading-4', {
        'w-full lg:w-11/12': isPlp
      })}
    >
      <span>
        {`on equipment for ${months} mos. At checkout, pay over time with`}{' '}
      </span>
      <AffirmInformationLogoIcon
        height="15px"
        onClick={onClick}
        priceInCents={priceInCents}
        useSystemTermMonths={useSystemTermMonths}
        width="38px"
      />
    </p>
  ) : (
    <p className="my-1 text-xs font-normal leading-4">
      To pay for equipment over time, you must add at least $150 to your cart.
    </p>
  )
}
