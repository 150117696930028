import { gql } from '@apollo/client/index.js'
import { FOOTER_FRAGMENT, footerSchema } from '@ecomm/footer-data'
import { useContentful } from '@ecomm/shared-apollo'

const FOOTER_QUERY = gql`
  ${FOOTER_FRAGMENT}
  query FOOTER_QUERY($preview: Boolean!, $locale: String!, $id: String!) {
    footer(id: $id, preview: $preview, locale: $locale) {
      ...footer
    }
  }
`

/**
 * ONLY FOR GATSBY TEMPLATE FILES!
 */
export const useFooterQuery = (id: string) => {
  const { data } = useContentful(FOOTER_QUERY, { id })
  return footerSchema.parse(data['footer'], {
    path: ['useFooterQuery', 'footer']
  })
}
