import { SHIELD_SKU } from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { ErrorBoundary } from '@ecomm/error-handling'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  type GatsbyImageSchema,
  ContentfulRichText,
  OfferTag,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import {
  useNinetailedImpactedEvent,
  useOdmonExperience
} from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { useEnv } from '@ecomm/utils'
import { SEOProductSnippet } from '@ecomm/utils'
import { window } from 'browser-monads-ts'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/ReadonlyArray'
import type { OrderedMap } from 'immutable'
import { useAtom } from 'jotai'
import React, { useEffect, useRef, useState } from 'react'
import { match } from 'ts-pattern'

import { itemQuantityAtom } from '../../atoms/draftCart/itemQuantityAtom'
import { monitoringAtom } from '../../atoms/draftCart/monitoringPlanAtom'
import { useDynamicTypeFetch } from '../../components/Package/useDynamicTypeFetch'
import { useProductContext } from '../../contexts/ProductContext'
import { OdmonAddToCartButtonPackage } from '../../experiments/ODMON/OdmonAddToCartButtonPackage'
import { ProSetupHighlighted } from '../../experiments/ProSetupHighlighted'
import AddToCartButton from '../AddToCartButton'
import ShippingEstimateDate from '../DraftCart/DraftCartShipping/ShippingEstimate'
import { getMonitoringPlanType } from '../DraftCart/helpers'
import { MonitoringPlan } from '../DraftCart/types'
import Guarantee from '../Guarantee'
import { DynamicPackageMonitoringWrapper } from '../IMAPS/DynamicPackageMonitoringWrapper'
import { shouldRenderMonitoring } from '../IMAPS/helpers'
import {
  OdmonMonitoringDynamic,
  OdmonMonitoringPackage
} from '../IMAPS/Odmon/OdmonMAPSTestContainer'
import { PackageMonitoringWrapper } from '../IMAPS/PackageMonitoringWrapper'
import PackageCarousel from '../PackageCarousel'
import PackageItems from '../PackageItems'
import PackagePrice from '../PackagePrice'
import {
  PersonalizePackage,
  PersonalizePackageButton
} from '../PersonalizePackage'
import type { ProductCardFragment } from '../ProductCard/schema'
import { ProSetup } from '../ProSetup'
import Affirm from './Affirm'
import usePackageContentfulRenderers from './contentfulRenderers'
import { usePackagePrice } from './hooks'
import useOdmonReplaceCameraItem from './hooks/useOdmonReplaceCameraItem'
import LoadingBanner from './LoadingBanner'
import type { PackageFragment } from './schema'

export type PackageItem = {
  readonly quantity: number
  readonly product: ProductCardFragment
}

type Props = {
  // package is a reserved keyword in TS strict mode
  readonly pkg: PackageFragment
  readonly attributeHash: string
  readonly metaTitle: string
  readonly metaDescription: string
  readonly isAffirmExperience?: boolean
}

// Aggregates products from CTFL with their quantities to avoid duplicates.
export const groupProducts = (
  products: readonly ProductCardFragment[]
): readonly PackageItem[] => {
  const counts = new Map<string, number>()
  products.forEach(product =>
    counts.set(product.sku, (counts.get(product.sku) || 0) + 1)
  )

  return (
    products
      // get an array of deduped product objects
      .filter(
        (product, idx) => products.findIndex(p => p.sku === product.sku) === idx
      )
      // fetch its count using the map created above
      .map(product => ({
        product: product,
        quantity: counts.get(product.sku) || 0
      }))
  )
}

function getIncludedProductsCount<T>(
  items: OrderedMap<string, number>,
  products: readonly T[]
) {
  return items.reduce((total, count) => total + count, 0) + products.length
}

const getItemImageWithBg = (item: PackageItem) => item.product.imageWithBg

export default function Package({
  pkg: {
    disclaimer,
    displayName,
    image: packageImage,
    packageProducts,
    personalizePackage,
    products,
    product,
    proSetup,
    monitoringToggles,
    type,
    slug,
    showPartnerPackageAbsoluteDiscountAsRelative
  },
  attributeHash,
  metaTitle,
  metaDescription,
  isAffirmExperience = false
}: Props) {
  /**
   * Component's state hooks
   */
  const isUs = useLocale() === 'en-US'
  const [hoveredIdx, setHoveredIdx] = useState(0)
  const [hasOutdoorCamera, setHasOutdoorCamera] = useState(false)
  const [error, setError] = useState(false)
  const [personalizeOpen, setPersonalizeOpen] = useState(false)
  const personalizeSectionRef = useRef<HTMLDivElement>(null)

  const shouldDisplayPersonalizeYourPackageButton: boolean = pipe(
    !personalizeOpen,
    O.fromPredicate(Boolean),
    O.fold(
      () => false,
      () =>
        pipe(
          personalizePackage,
          O.fromNullable,
          O.fold(
            () => false,
            ({ items }) => items.length > 0
          )
        )
    )
  )

  /**
   * End of component's state hooks
   */

  /**
   * External hooks
   */
  const [monitoringPlan, setMonitoringPlan] = useAtom(monitoringAtom)
  const [items] = useAtom(itemQuantityAtom)
  const { getProduct } = useProductContext()

  const microCopy = useMicroCopy()
  const { disclaimerRenderer } = usePackageContentfulRenderers()

  const { locale } = useEnv()
  const { isLoading } = useDynamicTypeFetch(
    locale,
    type,
    product,
    attributeHash
  )
  const odmonExperienceData = useOdmonExperience()

  /**
   * End of external hooks
   */

  const showProsetupHighlightedComponent =
    locale === 'en-US' && type !== 'Dynamic'

  const productItems: readonly PackageItem[] = groupProducts(products)

  const productImages: readonly GatsbyImageSchema[] = pipe(
    productItems,
    A.map(getItemImageWithBg),
    A.filter(
      (image): image is GatsbyImageSchema => typeof image !== 'undefined'
    )
  )

  const openPersonalizeSection = () => {
    setPersonalizeOpen(true)
    personalizeSectionRef.current &&
      personalizeSectionRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const closePersonalizeSection = () => {
    setPersonalizeOpen(false)
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }

  const title = match(type)
    .with('Dynamic', 'PLA', () =>
      pipe(
        getIncludedProductsCount(items, products),
        microCopy['dynamic-pkg-title']
      )
    )
    .otherwise(() => displayName)

  const personalizeYourPackageItems = useOdmonReplaceCameraItem(
    items
      .toArray()
      .flatMap(([sku, quantity]) => A.replicate(quantity, getProduct(sku)))
      .filter((item): item is ProductCardFragment => !!item),
    odmonExperienceData.isVariant
  )

  const includedProducts = match(type)
    .with('Dynamic', () =>
      groupProducts(products.concat(personalizeYourPackageItems))
    )
    .otherwise(() => productItems)

  const {
    regularPrice: totalPrice,
    discountedPrice,
    extrasPrice
  } = usePackagePrice(product, type, productItems)

  useEffect(() => {
    const initialPlan = monitoringToggles[0]?.sku ?? ''
    const mpType = getMonitoringPlanType(initialPlan, monitoringToggles)
    setMonitoringPlan({
      plan: initialPlan,
      type: mpType
    })
  }, [])

  useEffect(() => {
    const pkgIncludesOutdoorCamera = includedProducts.some(
      productObject =>
        productObject.quantity > 0 && productObject.product.sku === SHIELD_SKU
    )

    const personalizeIncludesOutdoorCamera = personalizeYourPackageItems.some(
      item => item.sku === SHIELD_SKU
    )

    setHasOutdoorCamera(
      personalizeIncludesOutdoorCamera || pkgIncludesOutdoorCamera
    )
  }, [includedProducts, personalizeYourPackageItems])

  const carouselImages = pipe(
    match(type)
      .with('Dynamic', () =>
        pipe(
          products.concat(personalizeYourPackageItems),
          groupProducts,
          A.map(getItemImageWithBg),
          A.filter(
            (image): image is GatsbyImageSchema => typeof image !== 'undefined'
          )
        )
      )
      .otherwise(() => productImages),
    images => (packageImage ? [packageImage].concat(images) : images)
  )

  const odmonTestObserver = useNinetailedImpactedEvent({
    baseline: odmonExperienceData.baseline,
    experiences: odmonExperienceData.experiences,
    name: 'nt_impacted_event',
    event: { hasOutdoorCamera },
    shouldReset: (e1, e2) => e1.hasOutdoorCamera !== e2.hasOutdoorCamera
  })

  // Only construct a valid SEO Product Schema if a price is resolved and greater than 0.
  // NOTE: Default fallbacks of 0 from pricing hooks is
  const seoProductPayload = React.useMemo(() => {
    // use discounted price, otherwise fall back to total price
    const price = O.getOrElse(() => totalPrice)(discountedPrice)
    if (price > 0 && type === 'PLA') {
      return {
        slug,
        sku: product,
        price
      }
    } else {
      return null
    }
  }, [discountedPrice, totalPrice, product, slug, type])

  const mapIncludedProductsToPackageProducts = (
    includedProds: readonly PackageItem[]
  ) =>
    includedProds.map(prod => ({
      quantity: prod.quantity,
      sku: prod.product.sku
    }))

  return isLoading ? (
    <LoadingBanner />
  ) : (
    <ErrorBoundary>
      <>
        {seoProductPayload !== null && (
          <SEOProductSnippet
            locale={locale}
            metaDescription={metaDescription}
            metaTitle={metaTitle}
            product={seoProductPayload}
          />
        )}
        <div className="prose md:prose-md lg:prose-lg whitespace-pre-line prose-h2:text-xl prose-h2:md:text-2xl prose-h2:font-default">
          {locale === 'en-GB' && (
            <TrustpilotUKTopBanner className="mb-6 mt-2 md:-mt-3"></TrustpilotUKTopBanner>
          )}
          <div className="flex flex-col gap-6 md:flex-row">
            <div className="relative flex-1 md:max-h-[450px] lg:max-h-[600px]">
              {(monitoringPlan.type === MonitoringPlan.interactive ||
                monitoringPlan.type === MonitoringPlan.odmonOvernight ||
                monitoringPlan.type === MonitoringPlan.odmon247) && (
                <OfferTag
                  className="absolute bottom-0 right-0 top-8 z-10 h-12 lg:w-auto lg:min-w-[15.5rem] lg:px-4"
                  showPartnerPackageAbsoluteDiscountAsRelative={
                    showPartnerPackageAbsoluteDiscountAsRelative
                      ? showPartnerPackageAbsoluteDiscountAsRelative
                      : false
                  }
                  sku={product}
                />
              )}
              <Guarantee type="desktop" />
              {shouldDisplayPersonalizeYourPackageButton ? (
                <PersonalizePackageButton setOpen={openPersonalizeSection} />
              ) : null}
              <PackageCarousel
                hoveredIdx={hoveredIdx}
                images={carouselImages}
              />
            </div>
            <div className="flex flex-1 flex-col gap-4" id="package-info">
              <h1 className="text-heading-2-size leading-h2-height m-0">
                {title}
              </h1>
              {disclaimer?.raw ? (
                <ContentfulRichText
                  optionsCustom={disclaimerRenderer}
                  raw={disclaimer.raw}
                  references={disclaimer?.references}
                />
              ) : null}
              <PackageItems
                hasOffset={!!packageImage}
                productItems={includedProducts}
                setHoveredIdx={setHoveredIdx}
                sku={product}
              />
              {isUs ? (
                <PackagePrice
                  isAffirmExperience={isAffirmExperience}
                  packageType={type}
                  productItems={productItems}
                  sku={product}
                />
              ) : null}
              {isUs && shouldRenderMonitoring(monitoringToggles) ? (
                <h2 className="mb-0 mt-2">Monitoring</h2>
              ) : null}
              {type === 'Dynamic' ? (
                isUs ? (
                  <div>
                    {odmonTestObserver}
                    <Experience
                      {...odmonExperienceData.data}
                      component={OdmonMonitoringDynamic}
                      experiences={odmonExperienceData.experiences}
                      passthroughProps={{
                        hasOutdoorCamera,
                        priceToggles: monitoringToggles,
                        proSetup: proSetup,
                        showProsetupHighlightedComponent:
                          showProsetupHighlightedComponent,
                        sku: product,
                        total: totalPrice
                      }}
                    />
                  </div>
                ) : (
                  <DynamicPackageMonitoringWrapper
                    priceToggles={monitoringToggles}
                    proSetup={proSetup}
                    showProsetupHighlightedComponent={
                      showProsetupHighlightedComponent
                    }
                    sku={product}
                    total={totalPrice}
                  />
                )
              ) : isUs ? (
                <div>
                  {odmonTestObserver}
                  <Experience
                    {...odmonExperienceData.data}
                    component={OdmonMonitoringPackage}
                    experiences={odmonExperienceData.experiences}
                    passthroughProps={{
                      hasOutdoorCamera,
                      priceToggles: monitoringToggles,
                      proSetup: proSetup,
                      showProsetupHighlightedComponent:
                        showProsetupHighlightedComponent,
                      sku: product,
                      subtotal: totalPrice + extrasPrice
                    }}
                  />
                </div>
              ) : (
                <PackageMonitoringWrapper
                  priceToggles={monitoringToggles}
                  proSetup={proSetup}
                  showProsetupHighlightedComponent={
                    showProsetupHighlightedComponent
                  }
                  sku={product}
                />
              )}
              {error ? (
                <p className="text-sale my-0 text-sm">
                  {microCopy['package-atc-error']}
                </p>
              ) : null}
              <div className="mt-0 flex gap-4">
                {isUs ? (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="hidden flex-col gap-0 md:flex">
                        <p className="m-0 text-sm">Order now for an expected</p>
                        <ShippingEstimateDate
                          className="m-0 text-sm font-semibold"
                          message="{{date}} ship date"
                        />
                      </div>
                      <OdmonAddToCartButtonPackage
                        className="!w-full md:!w-80"
                        packageName={displayName}
                        packageProducts={
                          packageProducts ??
                          mapIncludedProductsToPackageProducts(includedProducts)
                        }
                        packageSku={product}
                        packageType={type}
                        setError={setError}
                      />
                    </div>
                  </>
                ) : (
                  <AddToCartButton.Package
                    packageName={displayName}
                    packageProducts={
                      packageProducts ??
                      mapIncludedProductsToPackageProducts(includedProducts)
                    }
                    packageSku={product}
                    packageType={type}
                    setError={setError}
                  />
                )}

                {isUs ? null : (
                  <PackagePrice
                    isAffirmExperience={false}
                    packageType={type}
                    productItems={productItems}
                    sku={product}
                  />
                )}
              </div>
              {isUs ? null : (
                <Affirm productItems={productItems} sku={product} type={type} />
              )}
              {isUs ? null : <Guarantee type="mobile" />}
              {showProsetupHighlightedComponent
                ? proSetup && <ProSetupHighlighted proSetup={proSetup} />
                : proSetup &&
                  isUs && (
                    <div className="-mx-1">
                      <ProSetup proSetup={proSetup} />
                    </div>
                  )}
            </div>
          </div>
          {personalizePackage && personalizePackage.ready ? (
            <PersonalizePackage
              isAffirmExperience={isAffirmExperience}
              open={personalizeOpen}
              packageName={displayName}
              packageProducts={
                packageProducts ??
                mapIncludedProductsToPackageProducts(includedProducts)
              }
              packageType={type}
              productGroup={personalizePackage}
              productItems={productItems}
              ref={personalizeSectionRef}
              setClose={closePersonalizeSection}
              sku={product}
            />
          ) : null}
        </div>
      </>
    </ErrorBoundary>
  )
}
