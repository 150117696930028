import { useLocale } from '@ecomm/data-hooks'
import {
  useCurrentPromoBannerData,
  useDisplayMonitoringDiscount,
  usePromoDiscountText,
  usePromoWithMonitoringDiscountText,
  useTopBannerVisible
} from '@ecomm/promotions-hooks'
import {
  type HeroBannerProps,
  type HeroBannerSchema,
  HeroBanner
} from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/shared-hooks'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'

//This component was meant to extract the business logic from the shared HeroBanner component and to prevent having too many lines of code in the template
export default function HomeLandingHeroBanner({
  extraContent,
  ...heroBannerProps
}: HeroBannerProps) {
  const locale = useLocale()
  const isDesktop = useMediaQuery('DesktopAndUp')
  const isPromoTopBanner = useTopBannerVisible()

  const [placeholders, setPlaceholders] = useState<string | null>(null)
  const displayMonitoringDiscount = useDisplayMonitoringDiscount()
  const discountTextWithMonitoring = usePromoWithMonitoringDiscountText()
  const discountTextWithoutMonitoring = usePromoDiscountText()
  const discountText = displayMonitoringDiscount
    ? discountTextWithMonitoring
    : discountTextWithoutMonitoring

  const { heroStickerImage } = useCurrentPromoBannerData()

  const mappedMainExperiences = mapExperiences<HeroBannerSchema>(
    heroBannerProps.nt_experiences
  )

  useEffect(() => {
    discountText.cata(
      () => setPlaceholders(null),
      text => setPlaceholders(text.replace('%', ''))
    )
  }, [discountText])

  const discountPercentageText = !isPromoTopBanner ? placeholders : null

  const nestedHeroBannerProps = useStaticQuery(graphql`
    query NestedHeroBannerPropsQuery {
      nestedHeroBannerData: contentfulHeroBanner(
        contentful_id: { eq: "4MiGTqTgEN15rSWljwhpKU" }
      ) {
        ...homeLandingHeroBanner
        ...homeLandingHeroBannerVariations
        nt_experiences {
          ...heroBannerExperiences
        }
      }
    }
  `)

  const nestedMappedExperiences = mapExperiences<HeroBannerSchema>(
    nestedHeroBannerProps.nestedHeroBannerData?.nt_experiences
  )
  const isOdmonVariant = useOdmonExperience().isVariant

  return locale === 'en-US' ? (
    // We render a nested experiment if the user is in the odmon variant
    isOdmonVariant ? (
      <Experience
        {...nestedHeroBannerProps.nestedHeroBannerData}
        component={HeroBanner}
        experiences={nestedMappedExperiences}
        id={nestedHeroBannerProps.nestedHeroBannerData.id}
        key={nestedHeroBannerProps.nestedHeroBannerData.id}
        passthroughProps={{
          floatingBadgeDiscount: discountPercentageText,
          heroStickerImage: heroStickerImage,
          isFullWidth: isDesktop
            ? nestedHeroBannerProps.nestedHeroBannerData.isFullWidth
            : true,
          extraContent: extraContent
        }}
      />
    ) : (
      <Experience
        {...heroBannerProps}
        component={HeroBanner}
        experiences={mappedMainExperiences}
        id={heroBannerProps.id}
        key={heroBannerProps.id}
        passthroughProps={{
          floatingBadgeDiscount: discountPercentageText,
          heroStickerImage: heroStickerImage,
          isFullWidth: isDesktop ? heroBannerProps.isFullWidth : true,
          extraContent: extraContent
        }}
      />
    )
  ) : (
    <HeroBanner
      {...heroBannerProps}
      floatingBadgeDiscount={discountPercentageText}
      heroStickerImage={heroStickerImage}
      isFullWidth={isDesktop ? heroBannerProps.isFullWidth : true}
    />
  )
}
