import {
  AFFIRM_TERM_MONTHS_SYSTEM,
  AFFIRM_TERM_MONTHS_SYSTEM_VARIANT
} from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { getMatchingDynamicPrice, usePriceContext } from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import { AffirmMonthlyPricingBanner } from '@ecomm/shared-components'
import { Experience, mapExperiences } from '@ecomm/shared-ninetailed'
import { isNotEmpty, overloadMaybe } from '@simplisafe/ewok'
import { selectCurrentPromoOverrideDiscountText } from '@simplisafe/ss-ecomm-data/redux/select'
import { exists, window } from 'browser-monads-ts'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import { forwardRef, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { match } from 'ts-pattern'

import { monitoringAtom } from '../../atoms/draftCart/monitoringPlanAtom'
import type { AffirmFeatureFlagSchema } from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import { PersonalizePackageAddOnsVariation } from '../../experiments/PersonalizePackageAddOnsVariation'
import { MonitoringPlan } from '../DraftCart/types'
import type { PackageItem } from '../Package'
import { useFormattedPackagePrice, usePackagePrice } from '../Package/hooks'
import type { PackageProduct, PackageType } from '../Package/schema'
import type { ProductCardExperimentFragment } from '../ProductCard/schema'
import type { SensorGroupFragment } from '../SensorGroup/schema'
import { PersonalizePackageAddOns } from './PersonalizePackageAddOns'
import {
  getMatchingDynamicDiscount,
  getPriceAccordingToType
} from './priceUtils'
import Product from './Product'

export type Props = {
  readonly open: boolean
  readonly setClose: () => void
  readonly productGroup: SensorGroupFragment
  readonly sku: string
  readonly packageName: string
  readonly packageProducts: readonly PackageProduct[]
  readonly packageType: PackageType
  readonly productItems: readonly PackageItem[]
  readonly isAffirmExperience?: boolean
}

export default forwardRef<HTMLDivElement, Props>(function PersonalizePackage(
  {
    open,
    setClose,
    productGroup,
    sku,
    packageName,
    packageProducts,
    packageType,
    productItems,
    isAffirmExperience = false
  },
  ref
) {
  const [error, setError] = useState(false)
  const {
    getDiscountText,
    getDiscountTextWithServicePlan,
    getDynamicDiscountsThresholds
  } = usePriceContext()
  const locale = useLocale()

  const isUS = locale === 'en-US'

  const [monitoringPlan] = useAtom(monitoringAtom)

  const microCopy = useMicroCopy()

  const thresholds = pipe(getDynamicDiscountsThresholds(sku || ''))

  const promoDiscountText = pipe(
    getDiscountText(sku),
    O.getOrElse(() => '')
  )
  const promoWithMonitoringDiscountText = pipe(
    getDiscountTextWithServicePlan(sku),
    O.getOrElse(() => '')
  )
  const overrideText = pipe(
    overloadMaybe(useSelector(selectCurrentPromoOverrideDiscountText)),
    O.toUndefined,
    O.of
  )

  const isDynamicDiscount = pipe(
    thresholds,
    O.chain(t => (isNotEmpty(t) ? O.of(t) : O.none)),
    O.isSome
  )

  const { regularPrice, extrasPrice, discountedPrice, discountAmount } =
    useFormattedPackagePrice(sku, packageType, productItems)

  const monthlyPrices = useFormattedPackagePrice(
    sku,
    packageType,
    productItems,
    isAffirmExperience
      ? AFFIRM_TERM_MONTHS_SYSTEM_VARIANT
      : AFFIRM_TERM_MONTHS_SYSTEM
  )

  const {
    discountedPrice: affirmFullDiscountedPrice,
    regularPrice: affirmFullPrice,
    extrasPrice: unformattedExtrasPrice
  } = usePackagePrice(sku, packageType, productItems)

  const matchingDynamicDiscount = getMatchingDynamicDiscount(
    thresholds,
    affirmFullPrice + unformattedExtrasPrice
  )

  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()

  // BMSM v2 A/B test - Remove when test is over
  const formattedMatchingDynamicDiscount = pipe(
    thresholds,
    getMatchingDynamicPrice(affirmFullPrice + unformattedExtrasPrice),
    O.map(discount => getPriceAccordingToType(locale, discount)),
    O.getOrElse(() => '')
  )
  // BMSM v2 A/B test - Remove when test is over

  const discountText = useMemo(() => {
    const isPremiumMonitoring =
      monitoringPlan.type === MonitoringPlan.interactive ||
      monitoringPlan.type === MonitoringPlan.odmon247 ||
      monitoringPlan.type === MonitoringPlan.odmonOvernight

    return pipe(
      overrideText,
      O.map(overrideText => {
        const shouldApplyOverride =
          !!overrideText?.discountSummary?.isSome() && isPremiumMonitoring

        return shouldApplyOverride
          ? overrideText?.discountSummary?.orSome('')
          : isPremiumMonitoring
            ? isDynamicDiscount && matchingDynamicDiscount
              ? formattedMatchingDynamicDiscount
              : promoWithMonitoringDiscountText
            : promoDiscountText
      }),
      O.map(text => `+ ${text} Off`),
      O.map(text =>
        match(monitoringPlan.type)
          .with(
            MonitoringPlan.interactive,
            () => `${text} with ${microCopy['monitoring-name']}`
          )
          .with(
            MonitoringPlan.odmon247,
            () => `${text} with Pro Plus Monitoring`
          )
          .with(
            MonitoringPlan.odmonOvernight,
            () => `${text} with Pro Monitoring`
          )
          .otherwise(() => text)
      ),
      O.getOrElse(() => '')
    )
  }, [
    monitoringPlan.type,
    overrideText,
    isDynamicDiscount,
    matchingDynamicDiscount,
    formattedMatchingDynamicDiscount,
    promoWithMonitoringDiscountText,
    promoDiscountText,
    microCopy
  ])

  const isBrowser = exists(window)
  const affirmClient = isBrowser ? window.affirm : null

  return (
    <div ref={ref}>
      <div
        aria-label="Personalize package"
        className={classNames({ hidden: !open })}
      >
        <div
          className={classNames('flex justify-end', {
            'mt-8': isUS
          })}
        >
          <button
            aria-label="close"
            className="text-neutral-medium-120 cursor-pointer border-0 bg-inherit p-0"
            onClick={setClose}
          >
            <svg height="24" width="24">
              <title>close</title>
              <path
                d="M18.033 5L4.967 18.066M4.967 5l13.066 13.066"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
              ></path>
            </svg>
          </button>
        </div>
        {isUS ? (
          <AffirmMonthlyPricingBanner
            affirmClient={affirmClient}
            className="my-4 md:mt-12"
            discountedPrice={
              O.getOrElse(() => affirmFullPrice)(affirmFullDiscountedPrice) *
              100
            }
            isAffirmExperience={isAffirmExperience}
          />
        ) : null}
        {isUS
          ? productGroup.items.map(item => (
              <Experience
                key={item.sku}
                {...item}
                component={Product}
                experiences={mapExperiences<ProductCardExperimentFragment>(
                  item.nt_experiences
                )}
                passthroughProps={{
                  isAffirmExperience: isAffirmExperience
                }}
              />
            ))
          : productGroup.items.map(item => (
              <Product key={item.sku} {...item} isAffirmExperience={false} />
            ))}
        {isUS ? (
          <PersonalizePackageAddOnsVariation
            affirmFullDiscountedPrice={
              O.getOrElse(() => affirmFullPrice)(affirmFullDiscountedPrice) *
              100
            }
            affirmMappedExperiences={mapExperiences<AffirmFeatureFlagSchema>(
              affirmFeatureFlagQueryData.nt_experiences
            )}
            discountAmount={discountAmount}
            discountText={discountText}
            discountedPrice={discountedPrice}
            error={error}
            extrasPrice={extrasPrice}
            // passing this as a prop because component happens to be US-only and UK can't do 9T yet
            monthlyPrices={monthlyPrices}
            packageName={packageName}
            packageProducts={packageProducts}
            packageType={packageType}
            regularPrice={regularPrice}
            setError={setError}
            sku={sku}
          />
        ) : (
          <PersonalizePackageAddOns
            discountAmount={discountAmount}
            discountText={discountText}
            discountedPrice={discountedPrice}
            error={error}
            extrasPrice={extrasPrice}
            packageName={packageName}
            packageProducts={packageProducts}
            packageType={packageType}
            regularPrice={regularPrice}
            setError={setError}
            sku={sku}
          />
        )}
      </div>
    </div>
  )
})
