import classNames from 'classnames'
import React from 'react'
import type { Footer as Props } from '@ecomm/footer-data'
import { Copyright } from './Copyright'
import { CountrySelector } from './CountrySelector'
import { LegalLinks } from './LegalLinks'
import MenuAccordions from './MenuAccordions'
import OffersForm from './OffersForm'
import { SocialMediaLinks } from './SocialMediaLinks'

type FooterProps = Props & {
  readonly type?: 'Full' | 'Slim'
  readonly showCountrySelector?: boolean
}

const Footer = React.forwardRef<HTMLDivElement, FooterProps>(
  (
    {
      disclaimerLink,
      legalLinksCollection: { items: legalLinks },
      socialLinksCollection: { items: socialLinks },
      copyrightText,
      menusCollection: { items: menus },
      leadGenForm,
      type = 'Slim',
      showCountrySelector = true
    },
    ref
  ) => {
    const isSlim = type === 'Slim'

    return (
      <footer
        className="bg-neutral-black prose-headings:text-white prose-p:text-white prose-a:text-white text-white"
        data-component="Footer"
        ref={ref}
      >
        <div className="max-w-8xl mx-auto px-4 py-6 md:p-8">
          <div
            className={classNames(
              'mb-4 flex justify-between md:flex-row md:flex-wrap md:items-start lg:!flex-nowrap lg:items-start',
              {
                'flex-col': !isSlim
              }
            )}
          >
            {leadGenForm ? (
              <OffersForm
                className={classNames(
                  'mb-6 md:!block md:basis-2/3	lg:basis-2/5',
                  {
                    hidden: isSlim
                  }
                )}
                leadForm={leadGenForm}
              />
            ) : null}
            {menus ? (
              <MenuAccordions
                className={classNames(
                  'mb-6 md:order-last lg:!order-first lg:!mr-8 lg:!max-w-xl',
                  {
                    'basis-2/3 md:basis-1/3 lg:!basis-1/4 xl:!basis-1/4':
                      isSlim,
                    'md:flex md:basis-full md:justify-between lg:!basis-1/2 xl:!basis-1/2':
                      !isSlim
                  }
                )}
                menus={menus}
                type={type}
              />
            ) : null}
            {showCountrySelector ? (
              <CountrySelector className="flex justify-start md:basis-1/3 md:!items-center md:!justify-end lg:basis-1/6 lg:!items-baseline xl:basis-1/12" />
            ) : null}
          </div>
          <div
            className={classNames(
              'flex items-baseline justify-between md:flex-row md:justify-between',
              {
                'flex-col': isSlim,
                'justify-between': !isSlim
              }
            )}
          >
            {copyrightText ? (
              <Copyright
                className={classNames('basis-1/3 md:text-center', {
                  'order-last': isSlim
                })}
                text={copyrightText}
              />
            ) : null}
            {socialLinks ? (
              <SocialMediaLinks
                className="basis-1/3 justify-end pl-0 md:order-first md:justify-start"
                links={socialLinks}
              />
            ) : null}
            {legalLinks ? (
              <LegalLinks
                className={classNames(
                  {
                    hidden: !isSlim
                  },
                  'basis-1/3 list-none justify-center md:order-last md:!flex md:justify-center md:gap-1'
                )}
                links={legalLinks}
              />
            ) : null}
          </div>
          <div>
            {disclaimerLink?.text ? (
              <a
                className="ot-sdk-show-settings cursor-pointer text-xs text-white no-underline hover:underline"
                onClick={() => window?.OneTrust?.ToggleInfoDisplay()}
              >
                {disclaimerLink?.text}
              </a>
            ) : null}
          </div>
        </div>
      </footer>
    )
  }
)

export { Footer }
