import classNames from 'classnames'

import { HeroPromoSticker } from '../HeroPromoSticker'
import GradientWrapper from './GradientWrapper'
import HeroBannerColumn from './HeroBannerColumn'
import PromoBadge from './PromoBadge'
import type { HeroBannerSchema } from './schema'

export type HeroBannerProps = HeroBannerSchema & {
  readonly extraContent?: React.ReactNode
}

export function HeroBanner({
  content,
  className,
  floatingBadgeDiscount,
  floatingBadge,
  disclaimerText,
  disclaimerTextAlignment,
  heroStickerImage,
  heightType,
  isFullWidth,
  roundedCorners,
  extraContent = null
}: HeroBannerProps) {
  const bgColors = content.map(c => c.backgroundColor).filter(c => !!c)
  const hasForm = content.map(c => c.form).filter(f => !!f).length > 0
  const showHeroStickerImage = !!heroStickerImage

  return (
    <div className="mb-6 w-full md:mb-8 lg:mb-10">
      <GradientWrapper colors={bgColors} isFullWidth={isFullWidth}>
        <div
          className={classNames(
            'max-w-8xl relative mx-auto flex flex-col lg:flex-row',
            {
              'rounded-base overflow-hidden': !isFullWidth || roundedCorners
            },
            className
          )}
          data-component="HeroBanner"
        >
          {content.map((column, index) => (
            <HeroBannerColumn
              className={classNames(
                'relative w-full flex-auto px-4 py-6 md:px-8 md:py-16',
                {
                  'min-h-[350px] md:min-h-[400px] lg:min-h-[650px]':
                    heightType === 'responsive',
                  'lg:p-16': !isFullWidth,
                  'bg-neutral-light-100':
                    column.backgroundColor === 'neutralLight100' &&
                    !isFullWidth,
                  'bg-neutral-black':
                    column.backgroundColor === 'neutralBlack' && !isFullWidth,
                  'bg-primary-100':
                    column.backgroundColor === 'brandPrimary' && !isFullWidth,
                  'bg-complementary-blue-100':
                    column.backgroundColor === 'complementaryBlue100' &&
                    !isFullWidth,
                  'bg-neutral-light-200':
                    column.backgroundColor === 'neutralLight200' && !isFullWidth
                }
              )}
              extraContent={extraContent}
              key={`Hero-column-${index}`}
              {...column}
            />
          ))}
          {showHeroStickerImage && !hasForm ? (
            <HeroPromoSticker image={heroStickerImage} />
          ) : null}
        </div>
      </GradientWrapper>
      {floatingBadge ? (
        <div className="max-w-8xl relative mx-auto flex h-36 w-full justify-center md:h-20 lg:justify-end">
          <PromoBadge
            className="absolute -top-12 md:-top-28 lg:right-12"
            discountPercentage={floatingBadgeDiscount}
          />
        </div>
      ) : null}
      {disclaimerText ? (
        <small
          className={classNames(
            'hidden w-full text-neutral-dark md:block',
            disclaimerTextAlignment === 'left'
              ? 'max-w-8xl mx-auto pl-8 pr-5 pt-5 text-left'
              : 'px-5 pb-0 pt-5 text-right',
            {
              'max-w-8xl mx-auto px-8 pt-5': !isFullWidth
            }
          )}
        >
          {disclaimerText}
        </small>
      ) : null}
    </div>
  )
}
