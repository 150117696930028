import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { trackClickBuildASystemCta } from '@ecomm/cdp-tracking'
import { ContentfulRichText, NewFlag } from '@ecomm/shared-components'
import {
  SecureCheckmarkIcon,
  ShippingTruck,
  SupportIcon
} from '@ecomm/shared-icons'
import { Link } from 'gatsby'
import React, { ReactElement, ReactNode, useCallback, useState } from 'react'

import { Output as ComponentProps } from '../../hooks/useLocalizedData'
import QuantitySelector from '../QuantitySelector'
import { ImageGallery } from './ImageGallery'
import { IconTextSchema, schema } from './schema'

export type ProductHeroProps = ComponentProps<typeof schema>

const descriptionCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="text-xl font-light md:text-[1.4rem]">{children}</p>
    )
  }
}

const texts: Record<IconTextSchema, string> = {
  professionalMonitoring: 'First month of monitoring is free',
  shippingTruck: 'Free shipping with system order',
  shield: '60 day return policy'
}

const icons: Record<IconTextSchema, () => ReactNode> = {
  professionalMonitoring: () => (
    <SupportIcon
      className="full-current mr-2 shrink-0"
      height="26"
      width="26"
    />
  ),
  shippingTruck: () => (
    <ShippingTruck className="mr-2 shrink-0" height="26" width="26" />
  ),
  shield: () => (
    <SecureCheckmarkIcon className="mr-2 shrink-0" height="26" width="26" />
  )
}

export function ProductHero({
  data,
  atcButton = () => <div></div>,
  price = ''
}: {
  readonly data: ProductHeroProps
  readonly atcButton?: (qty: number) => ReactElement
  readonly price?: ReactNode
}) {
  const [quantity, setQuantity] = useState(1)

  const handleChange = useCallback(
    (increase: boolean) => {
      increase ? setQuantity(quantity + 1) : setQuantity(quantity - 1)
    },
    [quantity]
  )

  return (
    <div
      className="whitespace-pre-line prose md:prose-md lg:prose-lg inline-grid max-w-none grid-cols-12 items-stretch gap-x-4 text-left md:gap-x-6 lg:gap-x-8"
      data-component="ProductHero"
    >
      <ImageGallery productImages={data.productImages} />
      <div className="col-span-12 flex flex-col justify-center md:col-span-12 lg:col-span-6 lg:justify-end">
        <div className="mb-3 mt-0">
          {data.showNewFlag ? <NewFlag hasBackground={true} /> : null}
        </div>
        <h2 className="mb-6 mt-0">{data.productName}</h2>
      </div>
      <div className="col-span-12 lg:col-span-6">
        <div className="mb-6 mt-0" data-component="Description">
          <ContentfulRichText
            optionsCustom={descriptionCustomOptions}
            raw={data.description.raw}
          />
        </div>
        <div className="border-neutral-light-50 mb-3 inline-flex w-full items-center rounded-lg border-2 border-solid p-3 md:w-auto">
          <div className="mr-4 flex text-[22px] font-bold">{price}</div>
          <div className="mx-4">
            <QuantitySelector
              maxQuantity={4}
              name={''}
              onDecrease={() => handleChange(false)}
              onIncrease={() => handleChange(true)}
              quantity={quantity}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-col md:flex-row lg:h-14">
            {atcButton ? atcButton(quantity) : null}
            <Link
              className="btn btn-outlined btn-outlined-primary inline-block mb-4 w-full md:w-64"
              onClick={trackClickBuildASystemCta}
              to={data.buttonLink}
            >
              {data.buttonText}
            </Link>
          </div>
          {data.features ? (
            <ul className="list-none p-0">
              {data.features.map((feature, idx) => (
                <li className={'mb-3'} key={idx}>
                  <span className="flex">
                    {icons[feature]()}
                    {texts[feature]}
                  </span>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  )
}
