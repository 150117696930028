import { toItemList } from '@ecomm/cart-line-items'
import {
  getCartDiscountCode,
  getCartDiscountValue,
  getShippingInfoPrice
} from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import { overloadMaybe, prop } from '@simplisafe/ewok'
import {
  ImmutableCart,
  getCartDetails
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { selectHiddenProductSkus } from '@simplisafe/ss-ecomm-data/redux/select'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { OrderSummaryContent } from '../content'
import { OdmonPreLaunchOrderSummaryContent } from '../experiments/OdmonPreLaunchOrderSummaryContent'
import {
  formatPrice,
  getShipppingLabel,
  toPriceCalculationData,
  toPriceCalculationFields
} from '../utils'

export const useRenderOrderSummary = () => {
  const hiddenProductSkus = useSelector(selectHiddenProductSkus)
  const { trackEvent } = useTracking()
  const locale = useLocale()

  return (cart: ImmutableCart) => {
    const lineItems =
      toItemList(getCartDetails(hiddenProductSkus)(cart.lineItems))(
        trackEvent,
        undefined,
        undefined
      ) || []

    const total = prop('totalPrice', cart)
    const subTotal = prop('subTotal', cart)
    const taxedPrice = pipe(
      overloadMaybe(prop('taxedPrice', cart)),
      O.getOrElse(() => 0)
    )
    const discountValue = pipe(
      //@ts-expect-error
      getCartDiscountValue(cart, locale),
      O.getOrElse(() => '')
    )
    const discountCode = getCartDiscountCode(cart)
    const shippingInfoPrice = getShippingInfoPrice(cart)
    const shippingPrice = formatPrice(locale, shippingInfoPrice)
    const shippingLabel = pipe(
      getShipppingLabel(shippingInfoPrice, cart.shippingInfo),
      O.getOrElse(() => '')
    )
    const cartSubtotal = formatPrice(locale, subTotal)
    const cartTotal = formatPrice(locale, total)
    const vAT = formatPrice(locale, taxedPrice)

    const priceCalculationData = toPriceCalculationData(
      shippingPrice,
      discountCode,
      discountValue,
      shippingLabel,
      cartSubtotal,
      cartTotal,
      vAT
    )

    const priceCalculationFields = toPriceCalculationFields(
      priceCalculationData,
      locale
    )

    return locale === 'en-US' ? (
      <OdmonPreLaunchOrderSummaryContent
        itemList={lineItems}
        priceCalculationFields={priceCalculationFields}
      />
    ) : (
      <OrderSummaryContent
        itemList={lineItems}
        priceCalculationFields={priceCalculationFields}
      />
    )
  }
}
