import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import { Header } from '@ecomm/header-redesigned'
import {
  ApplyPromoCode,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { HeroBanner, TrustpilotUKTopBanner } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { useState } from 'react'
import { match } from 'ts-pattern'

import BusinessSecurityCollection from '../../components/BusinessSecurity/BusinessSecurityCollection'
import BusinessSelector from '../../components/BusinessSecurity/BusinessSelector'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import type { SeoNodeSchema } from '../../schemas/seo'
import { parseBusinessSecuritySchema } from './schema'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly footerId: string
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function BusinessSecurity<
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>({ data, pageContext: { locale, seoDetails, footerId } }: Props<T>) {
  const { content } = parseBusinessSecuritySchema(data)
  const headerData = useHeaderRedesignQuery()
  const [selectedOption, setSelectedOption] = useState(-1)
  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={'business-security'}>
      <SEO
        canonicalLink={seoDetails.canonicalLink ?? ''}
        isNofollow={seoDetails.isNofollow}
        isNoindex={seoDetails.isNoindex}
        lang={locale}
        metaDescription={seoDetails.metaDescription?.metaDescription ?? ''}
        metaKeywords={seoDetails.metaKeywords ?? []}
        metaTitle={seoDetails.metaTitle}
      />
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <ApplyPromoCode />
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={'standard'}
        />
        <Header {...headerData} />
        {locale === 'en-GB' ? (
          <TrustpilotUKTopBanner className="pt-4 md:pt-6 lg:pt-8" />
        ) : null}
        <div className="max-w-8xl mx-auto mb-16 flex flex-col gap-6 px-4 pb-6 pt-0 md:gap-8 md:px-8 md:pb-10 lg:gap-12 lg:px-8 lg:pb-12">
          {content.components
            .filter(c => c.__typename !== 'ContentfulContentCollection')
            .map(component =>
              match(component)
                .with({ __typename: 'ContentfulHeroBanner' }, data => (
                  <HeroBanner
                    className="-mx-4 md:-mx-8"
                    {...data}
                    key={data.id}
                  />
                ))
                .with({ __typename: 'ContentfulLayoutReference' }, data => (
                  <BusinessSelector
                    key={data.referenceId}
                    onSelectOption={opt => setSelectedOption(opt)}
                    selectedOption={selectedOption}
                  />
                ))
                .otherwise(() => null)
            )}
          {content.components
            .filter(c => c.__typename === 'ContentfulContentCollection')
            .map((contentCollection, i) =>
              i === selectedOption || locale === 'en-GB' ? (
                <BusinessSecurityCollection
                  {...contentCollection}
                  key={`collection-${i}`}
                />
              ) : null
            )}
        </div>
        <Footer footerId={footerId} />
      </div>
    </TrackingProvider>
  )
}

export const query = graphql`
  #graphql
  query BusinessSecurityPage {
    content: contentfulLayout(contentful_id: { eq: "5QXv5yxtwpFjrJTdrSAJBY" }) {
      ...businessSecurityFragment
    }
  }
`
