import { trackCallExpert } from '@ecomm/cdp-tracking'
import { GatsbyImage } from '@ecomm/shared-components'
import {
  Floorplan,
  HeadsetPerson,
  HomeBase,
  ProInstall
} from '@ecomm/shared-icons'
import { useTrackHomepage } from '@ecomm/tracking'
import { useEnv } from '@ecomm/utils'
import React from 'react'

import { ExpertSectionSchema } from './schema'
import { useAreExpertsAvailable } from './useAreExpertsAvailable'

export type ButtonProps = {
  readonly classes: string
  readonly href: string
  readonly target?: string
  readonly text: string
}

const PHONE_NUMBER_US = '1-800-304-3601'
const PHONE_NUMBER_UK = '0800-456-1789'

export function ExpertSection({
  data
}: {
  readonly data: ExpertSectionSchema
}) {
  const { locale } = useEnv()
  const primaryBtnClasses =
    'cursor-pointer rounded-base bg-btn-primary px-4 py-3 text-center text-white no-underline transition-colors duration-200 ease-in-out hover:bg-btn-dark md:px-6 even:mt-4 lg:even:ml-6 lg:even:mt-0'
  const primaryOutlineBtnClasses =
    'mt-4 w-full cursor-pointer rounded-base border-2 border-solid border-complementary-blue-100 bg-transparent px-4 py-3 text-center leading-normal text-complementary-blue-100 no-underline transition-colors duration-200 ease-in-out hover:bg-complementary-blue-100 hover:text-white lg:mt-0 md:w-auto md:px-6 even:mt-4 lg:even:ml-6 lg:even:mt-0'
  const buttonsData: Record<
    string,
    readonly React.AnchorHTMLAttributes<HTMLAnchorElement>[]
  > = {
    'en-US': [
      {
        className: primaryBtnClasses,
        href: `tel:${PHONE_NUMBER_US}`,
        children: `Call ${PHONE_NUMBER_US}`,
        onClick: () => {
          // Fix for Firefox cancelling tracking call on link click
          setTimeout(() => {
            trackButtonClicks('button-click-call-expert-component')
            trackCallExpert()
          }, 1000)
        }
      }
    ],
    'en-GB': [
      {
        className: primaryBtnClasses,
        href: `tel:${PHONE_NUMBER_UK}`,
        children: `Call ${PHONE_NUMBER_UK}`,
        onClick: () => {
          // Fix for Firefox cancelling tracking call on link click
          setTimeout(() => {
            trackButtonClicks('button-click-call-expert-component')
            trackCallExpert()
          }, 1000)
        }
      },
      {
        className: primaryOutlineBtnClasses,
        href: 'https://calendly.com/simplisafe-uk/simplisafe-call-back',
        target: '_blank',
        children: `Schedule a call`
      }
    ]
  }
  const areExpertsAvailable = useAreExpertsAvailable()
  const trackButtonClicks = useTrackHomepage()

  const renderButtons = () => {
    return buttonsData[locale].map((data, index) => {
      return <a {...data} key={index} target={data.target || '_self'} />
    })
  }

  return (
    <div data-component="ExpertSection">
      <h2 className="mb-4 text-center text-[28px] leading-8 md:mb-6 lg:hidden">
        {data.title ?? 'Find your perfect system with a security expert'}
      </h2>
      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:gap-16">
        <GatsbyImage className="rounded-2xl lg:order-2" image={data.image} />
        <div className="flex flex-col justify-center">
          <h2 className="mb-11 hidden text-center lg:block">
            {data.title ?? 'Find your perfect system with a security expert'}
          </h2>
          {/* explicitly set list role for Safari (https://developer.mozilla.org/en-US/docs/Web/CSS/list-style#accessibility_concerns) */}
          <ul className="mb-6 mt-0 flex list-none flex-col gap-y-4 p-0 lg:mx-16 lg:mb-12 lg:grid lg:grid-cols-2 lg:gap-x-14 lg:gap-y-6">
            <li className="flex items-center text-sm lg:flex-col lg:gap-5 lg:text-center lg:text-lg">
              <HeadsetPerson className="text-neutral-black mr-5 h-5 w-5 lg:mr-0 lg:h-16 lg:w-16" />
              <div>Your questions answered</div>
            </li>
            <li className="flex items-center text-sm lg:flex-col lg:gap-5 lg:text-center lg:text-lg">
              <Floorplan className="text-neutral-black mr-5 h-5 w-5 lg:mr-0 lg:h-16 lg:w-16 " />
              <div>Tips for your home and budget</div>
            </li>
            <li className="flex items-center text-sm lg:flex-col lg:gap-5 lg:text-center lg:text-lg">
              <HomeBase className="text-neutral-black mr-5 h-5 w-5 lg:mr-0 lg:h-16 lg:w-16 " />
              <div>Build a system for your needs</div>
            </li>
            <li className="flex items-center text-sm lg:flex-col lg:gap-5 lg:text-center lg:text-lg">
              <ProInstall className="text-neutral-black mr-5 h-5 w-5 lg:mr-0 lg:h-16 lg:w-16 " />
              <div>See if Pro Install is right for you</div>
            </li>
          </ul>
          {areExpertsAvailable || locale === 'en-GB' ? (
            <div className="contents lg:flex lg:justify-center">
              {renderButtons()}
            </div>
          ) : (
            <div className="lg:text-center">
              <h3 className="mb-0 mt-0 text-base font-medium lg:text-xl">
                Come back soon to shop with a security expert
              </h3>
              <p
                className={
                  'text-neutral-dark mb-0 mt-3 text-sm font-light lg:text-lg'
                }
              >
                Our security experts are available by phone every day from
                8:00am - Midnight ET
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
