import classNames from 'classnames'
import React from 'react'

import { PriceBox } from '../../PriceBox'
import { localizedPrice } from '..'

type Props = {
  readonly discountedPrice?: number
  readonly dueTodayMicroCopy: string
  readonly payInFullMicroCopy: string
  readonly stacked?: boolean
  readonly subTotalPrice: number
  readonly isPlp?: boolean
}

export function FullOption({
  stacked,
  discountedPrice,
  dueTodayMicroCopy,
  payInFullMicroCopy,
  subTotalPrice,
  isPlp = false
}: Props) {
  return (
    <div
      className={classNames('w-full py-1', {
        'md:w-1/2': !stacked
      })}
      data-component="full"
    >
      <div
        className={classNames(
          'flex',
          isPlp
            ? 'flex-col gap-2 [&>*]:my-1'
            : 'flex min-h-[28px] flex-row content-center justify-between'
        )}
      >
        <p
          className={classNames(
            'm-0 align-bottom text-sm font-bold leading-none',
            {
              'self-center': !isPlp
            }
          )}
        >
          {payInFullMicroCopy}
        </p>
        <div
          className={classNames('font-bold', {
            'text-lg': !isPlp,
            '!-mt-0 text-base': isPlp
          })}
        >
          <PriceBox
            discountedPrice={localizedPrice('en-US', discountedPrice)}
            regularPrice={localizedPrice('en-US', subTotalPrice)}
          />
        </div>
      </div>
      <p className="my-1 text-xs font-normal leading-4">{dueTodayMicroCopy}</p>
    </div>
  )
}
