import { gql } from '@apollo/client/index.js'
import { FOOTER_FRAGMENT, footerSchema } from '@ecomm/footer-data'
import { useContentful } from '@ecomm/shared-apollo'

const CITY_STATE_FOOTER_QUERY = gql`
  ${FOOTER_FRAGMENT}
  query CITY_STATE_FOOTER_QUERY($preview: Boolean!, $locale: String!) {
    footer(id: "2SCKVa13yRuLSU4GuooHlb", locale: $locale, preview: $preview) {
      ...footer
    }
  }
`

const useFooterQuery = () => {
  const { data } = useContentful(CITY_STATE_FOOTER_QUERY)
  return footerSchema.parse(data.footer)
}

export default useFooterQuery
