import { DraftCartMonitoringWrapper } from '../DraftCartMonitoringWrapper'
import { PackageMonitoringWrapper } from '../PackageMonitoringWrapper'
import { DynamicPackageMonitoringWrapper } from '../DynamicPackageMonitoringWrapper'
import { OdmonMAPSCoreMonitoring } from './OdmonMAPSCoreMonitoring'
import { OdmonMAPSProMonitoring } from './OdmonMAPSProMonitoring'
import type { PriceToggleProps } from '../types'
import type { OdmonFeatureFlagDraftSchema } from '../../../experiments/ODMONPrelaunch/schema'
import type { PackageMonitoringWrapperProps } from '../PackageMonitoringWrapper'
import type { DynamicPackageMonitoringWrapperProps } from '../DynamicPackageMonitoringWrapper'
import { useAmountSavedWithServicePlanBMS } from '../../../hooks/IMAPS/useAmountSavedWithServicePlanBMS'
import { useAmountSavedWithServicePlanPackage } from '../../../hooks/IMAPS/useAmountSavedWithServicePlanPackage'
import { useDiscountWithServicePlan } from '../../../hooks/IMAPS/useDiscountWithServicePlan'

type OdmonHasOutdoorCameraProp = {
  readonly hasOutdoorCamera: boolean
}
type OdmonDraftProps = OdmonFeatureFlagDraftSchema &
  OdmonHasOutdoorCameraProp &
  PriceToggleProps
type OdmonDynamicPackageProps = DynamicPackageMonitoringWrapperProps &
  OdmonFeatureFlagDraftSchema &
  OdmonHasOutdoorCameraProp
type OdmonPackageProps = OdmonFeatureFlagDraftSchema &
  OdmonHasOutdoorCameraProp &
  PackageMonitoringWrapperProps

export function OdmonMonitoringDraftCart({
  hasOutdoorCamera,
  json,
  priceToggles,
  sku,
  total
}: OdmonDraftProps) {
  const { discountWithServicePlan } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanBMS()

  switch (json?.tag) {
    case 'variant-1':
      return hasOutdoorCamera ? (
        <OdmonMAPSProMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      ) : (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
    case 'control':
    default:
      return (
        <DraftCartMonitoringWrapper
          priceToggles={priceToggles}
          sku={sku}
          total={total}
        />
      )
  }
}

export function OdmonMonitoringDynamic({
  json,
  priceToggles,
  proSetup,
  showProsetupHighlightedComponent,
  sku,
  total
}: OdmonDynamicPackageProps) {
  const { discountWithServicePlan } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanBMS(total)

  switch (json?.tag) {
    case 'variant-1':
      return (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
    case 'control':
    default:
      return (
        <DynamicPackageMonitoringWrapper
          priceToggles={priceToggles}
          proSetup={proSetup}
          showProsetupHighlightedComponent={showProsetupHighlightedComponent}
          sku={sku}
          total={total}
        />
      )
  }
}

export function OdmonMonitoringPackage({
  hasOutdoorCamera,
  json,
  priceToggles,
  proSetup,
  showProsetupHighlightedComponent,
  sku
}: OdmonPackageProps) {
  const { discountWithServicePlan } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanPackage({
    sku: sku || ''
  })

  switch (json?.tag) {
    case 'variant-1':
      return hasOutdoorCamera ? (
        <OdmonMAPSProMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      ) : (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
    case 'control':
    default:
      return (
        <PackageMonitoringWrapper
          priceToggles={priceToggles}
          proSetup={proSetup}
          showProsetupHighlightedComponent={showProsetupHighlightedComponent}
          sku={sku}
        />
      )
  }
}
