export const AFFIRM_MIN_PRICE = 150

/* Default Affirm Values  */
export const AFFIRM_FINANCING_PROGRAM = 'fp_24z'
export const AFFIRM_PROMO_ID = 'fp_24z'
export const AFFIRM_TERM_MONTHS_SYSTEM = 24

export const AFFIRM_PROMO_NO_SYSTEM = 'promo_set_nonsystem'
export const AFFIRM_NO_SYSTEM_FINANCING_PROGRAM = 'nonsystem'

/* Affirm A/B Experiment Setup */
export const AFFIRM_FINANCING_PROGRAM_VARIANT = 'fp_36z'
export const AFFIRM_PROMO_ID_VARIANT = 'fp_36z'
export const AFFIRM_TERM_MONTHS_SYSTEM_VARIANT = 36

/* Affirm A/B Experiment CTFL Setup */
export const AFFIRM_NINETAILED_CTFL_EXPERIENCE = '4HaFKfL7XyQFiSllKCzamf'
export const AFFIRM_NINETAILED_CTFL_CONTROL_ID = '5F2YiuOmFiuL5TNNZTdaaP'
export const AFFIRM_NINETAILED_CTFL_VARIANT_ID = '1aW2D5ElC3k8hOCE10hNsK'

/**
 * @deprecated We don't use optimizely anymore
 * EXPERIMENT  https://simplisafe.atlassian.net/browse/ECP-8737
 */
export const AFFIRM_MONTHLY_EXPERIMENT_IMPACTED_EVENT = 'impacted_26719181849'
