import {
  affirmBannerSchema,
  buttonSchema,
  heroBannerSchema
} from '@ecomm/shared-components'
import {
  anchorSectionSchema,
  coloredSectionSchema,
  duplexSectionSchema,
  featureSectionSchema,
  reviewSectionSchema,
  twoColumnTextSchema
} from '@ecomm/shared-sections'
import { type TypeOf, z } from '@simplisafe/ewok'

import { trustPilotSchema } from '../../schemas/trustPilot'
import { layoutReferenceSchema } from '../LayoutReference/schema'
import { smallTextSchema } from '../SmallText/schema'

const gatsbyGrid = z.object({
  contentful_id: z.string(),
  __typename: z.literal('ContentfulGrid')
})

export const componentSchema = z.union([
  reviewSectionSchema,
  gatsbyGrid,
  coloredSectionSchema,
  duplexSectionSchema,
  featureSectionSchema,
  buttonSchema,
  trustPilotSchema,
  affirmBannerSchema,
  anchorSectionSchema,
  heroBannerSchema,
  layoutReferenceSchema,
  twoColumnTextSchema,
  smallTextSchema
])

export const contentCollectionSchema = z.object({
  content: componentSchema.array(),
  __typename: z.literal('ContentfulContentCollection')
})

export type ComponentTypes = TypeOf<typeof componentSchema>
export type ContentCollectionProps = TypeOf<typeof contentCollectionSchema>
