import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'

import { GatsbyImage } from '../GatsbyImage'
import { PackageCardSchema } from './schema'

export type PackageCardProps = {
  readonly data: PackageCardSchema
}

export function PackageCard({ data }: PackageCardProps) {
  return (
    <div
      className={classNames(
        'bg-neutral-light-100 grid pt-0 text-center sm:grid-cols-1 md:grid-cols-2 md:text-left lg:grid-cols-1 lg:text-center'
      )}
    >
      <div className="md:order-second p-2 text-2xl md:pl-10 md:pt-6 lg:pl-0 lg:pt-2">
        {data.title}
        {data.quantity && data.price ? (
          <div className="grid grid-cols-2 text-[18px]">
            <div>{data.quantity} Pieces</div>
            <div>{data.price}</div>
          </div>
        ) : (
          <div className="text-[18px]"> {data.bmsDescription} </div>
        )}
      </div>
      <div className="md:order-third h-64 md:pb-0 lg:pb-16">
        {data.image ? (
          <GatsbyImage
            image={data.image}
            style={{
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'relative',
              width: '100%'
            }}
          />
        ) : null}
      </div>
      <div className="md:order-second relative p-5 md:absolute md:bottom-0 md:pl-10 lg:relative lg:pl-0">
        <Link className={data.button.classes} to={data.button.href}>
          {' '}
          {data.button.value}{' '}
        </Link>
      </div>
    </div>
  )
}
