import { HeroBanner } from '@ecomm/city-state-components'
import { Footer } from '@ecomm/footer-components'
import { Breadcrumbs, Header } from '@ecomm/header-redesigned'
import { useGuaranteeAssetQuery, useGuaranteeCopyQuery } from '@ecomm/lander'
import {
  type ComparisonTableProps,
  ComparisonTable,
  ConfidenceBar,
  ExpertSection,
  GuaranteeSection,
  parseJSONDataFromContentful,
  QuoteWizardSection,
  transformToComparisonTableData
} from '@ecomm/scout'
import { AffirmBanner } from '@ecomm/shared-components'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import { TrackingProvider } from '@ecomm/tracking'
import type { Locale } from '@ecomm/utils'
import { SEO } from '@ecomm/utils'
import classNames from 'classnames'
import type { PageProps } from 'gatsby'
import { Suspense } from 'react'

import { type BestSystemsProps, BestSystems } from '../components/BestSystems'
import {
  type DropdownRedirectorProps,
  DropdownRedirector
} from '../components/DropdownRedirector'
import { type LinksGridProps, LinksGrid } from '../components/LinksGrid'
import { useHeaderRedesignQuery } from '../queries/header'
import { useHeroBannerQuery } from '../queries/heroBannerQuery'
import {
  useLocationPageQuery,
  useStaticLocationData
} from '../queries/location'
import useFooterQuery from '../queries/useFooterQuery'

export type PageContext = {
  readonly locale: Locale
  readonly states: readonly {
    readonly name: string
    readonly url: string
  }[]
}

export type Props<T> = Pick<PageProps<T, PageContext, unknown>, 'pageContext'>

function Content<T>({ pageContext: { states, locale } }: Props<T>) {
  const headerData = useHeaderRedesignQuery()
  const locationPageData = useLocationPageQuery()
  const footerData = useFooterQuery()
  const guaranteeAsset = useGuaranteeAssetQuery()
  const isOdmonVariant = useOdmonExperience().isVariant

  const { confidenceBarData } = useStaticLocationData()
  const heroBannerData = useHeroBannerQuery('79eORzoSFK2lAV3jFVbPC7')

  const bestSystemsData: BestSystemsProps = parseJSONDataFromContentful(
    locationPageData,
    'bestSystems'
  )
  const guaranteeCopy = useGuaranteeCopyQuery()
  const comparisonTableData: ComparisonTableProps = isOdmonVariant
    ? transformToComparisonTableData(
        locationPageData,
        'comparisonTableOdmonVariant'
      )
    : transformToComparisonTableData(locationPageData)
  const dropdownRedirectorData: DropdownRedirectorProps = {
    ...parseJSONDataFromContentful(locationPageData, 'dropdownRedirector'),
    links: states
  }
  const linksGridData: LinksGridProps = {
    ...parseJSONDataFromContentful(locationPageData, 'linksGrid'),
    links: states
  }

  const { seo } = locationPageData

  // Tailwind classes for non full-width elements only. Don't apply them to the Footer, DropdownRedirector or LinksGrid
  const wrapperClasses =
    'prose mx-auto flex max-w-8xl flex-col gap-14 whitespace-pre-line px-4 pt-8 md:prose-md lg:prose-lg md:gap-16 md:px-8 lg:gap-20'

  return (
    <TrackingProvider metaTitle={seo.metaTitle}>
      <SEO
        canonicalLink={seo.canonicalLink}
        isLandingPage={true}
        isNofollow={seo.isNofollow}
        isNoindex={seo.isNoindex}
        lang={locale}
        metaDescription={seo.metaDescription.metaDescription}
        metaKeywords={seo.metaKeywords ?? []}
        metaTitle={seo.metaTitle}
      />
      <Header {...headerData} />
      <Breadcrumbs
        steps={[
          {
            label: 'Locations',
            slug: 'location'
          }
        ]}
      />
      <div className="mx-auto mt-5 max-w-[1372px] lg:-mt-3">
        <HeroBanner heroBannerProps={heroBannerData} />
      </div>

      <div
        className={classNames(
          wrapperClasses,
          'mb-10 md:-mt-6 md:mb-16 lg:mb-20'
        )}
      >
        <ConfidenceBar data={confidenceBarData.content} />
        <AffirmBanner data={locationPageData.contentfulAffirmBanner} />
      </div>
      <DropdownRedirector {...dropdownRedirectorData} />
      <div
        className={classNames(wrapperClasses, 'mb-14 mt-8 md:mb-20 lg:mb-28')}
      >
        <BestSystems {...bestSystemsData} />
        <QuoteWizardSection
          hideExtraTextOnMobile={false}
          image={locationPageData.quoteWizardAsset}
          quoteWizard={locationPageData.quoteWizard}
        />
        <ComparisonTable data={comparisonTableData} />
        <GuaranteeSection
          copy={guaranteeCopy}
          image={guaranteeAsset.guaranteeAsset}
          quoteWizard={locationPageData.quoteWizard}
        />
        <ExpertSection data={{ image: locationPageData.expertSectionAsset }} />
      </div>
      <LinksGrid {...linksGridData} />
      <Footer {...footerData} type="Full" />
    </TrackingProvider>
  )
}

export default function LocationTemplate<T>(props: Props<T>) {
  return (
    <Suspense fallback={null}>
      <Content {...props} />
    </Suspense>
  )
}
