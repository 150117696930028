import { usePriceContext } from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import { ContentfulRichText, GatsbyImage } from '@ecomm/shared-components'
import { useLocation } from '@reach/router'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { Link } from 'gatsby'
import { useState } from 'react'

import { ProductSchema } from '../../../templates/SensorPage/sensorPageSchema'
import AddExtraSensorsModal from '../AddExtraSensorsModal'
import AddToCartControls from '../AddToCartControls'

type SensorHeroProps = {
  readonly product: ProductSchema
  readonly isOnStock?: boolean
  readonly isSellable?: boolean
  readonly type?: string
}

export default function SensorHero({
  product,
  isOnStock = true,
  isSellable = true,
  type = ''
}: SensorHeroProps) {
  const [showModal, setShowModal] = useState(false)
  const { namePlural, longDescription, imageWithBg, maxQuantity, sku } = product
  const { getFormattedPrice } = usePriceContext()
  const price = pipe(
    getFormattedPrice(sku, 1),
    O.getOrElse(() => '')
  )

  const microcopy = useMicroCopy()
  const buttonText: Record<string, string | undefined> = {
    sensor: microcopy['buy-extra-sensors'],
    accessory: microcopy['buy-accessory'],
    'base-station': ''
  }

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const showAddToCart = params.get('show_add_to_cart') === 'true'

  return (
    <>
      <section className="flex flex-col items-center gap-4 md:flex-row md:gap-8 lg:gap-12">
        <div className="relative basis-full md:basis-1/2">
          <GatsbyImage className="rounded-base" image={imageWithBg} />
          {product.isNew ? (
            <span className="bg-primary-100 absolute right-0 top-6 px-2 py-1 font-medium">
              {microcopy['new']}
            </span>
          ) : null}
        </div>
        <div className="prose-headings:mb-4 prose-p:mb-3 md:prose-headings:mb-6 basis-full md:basis-1/2">
          <p>
            {namePlural}
            {product.isNew ? (
              <span className="text-primary-100 ml-2 font-medium">
                {microcopy['new']}
              </span>
            ) : null}
          </p>
          <ContentfulRichText {...longDescription} />
          {!isOnStock ? (
            <p className="text-sale mb-2 w-full">{microcopy['out-of-stock']}</p>
          ) : null}
          {!showAddToCart ? (
            <div className="flex w-full items-center gap-4 md:gap-8">
              <Link
                className="btn max-h-14 btn-solid-primary"
                to="/build-my-system"
              >
                {microcopy['build-your-security-system']}
              </Link>
              {!!buttonText[type] ? (
                <button
                  className="text-body-size cursor-pointer border-none bg-transparent underline hover:no-underline"
                  data-component="add-product-modal-activator"
                  onClick={() => setShowModal(true)}
                >
                  {buttonText[type]}
                </button>
              ) : null}
            </div>
          ) : (
            <AddToCartControls
              isOnStock={isOnStock}
              isSellable={isSellable}
              maxQuantity={maxQuantity}
              price={price}
              sku={sku}
            />
          )}
        </div>
      </section>
      {/* Add out of stock span in case there's no stock */}
      <AddExtraSensorsModal
        isOnStock={isOnStock}
        isSellable={isSellable}
        price={price}
        product={product}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </>
  )
}
