import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

import { affirmReviewGridSchema } from '../../components/Financing/AffirmReviewGrid/schema'
import { confidenceBannerSchema } from '../../components/Financing/ConfidenceBanner/schema'
import { financingBannerSchema } from '../../components/Financing/FinancingBanner/schema'
import { financingSmallTextSchema } from '../../components/Financing/FinancingSmallText/schema'
import { packageCardFinancingGridSchema } from '../../components/Financing/PackageCardFinancingGrid/schema'
import type { contentfulImageQuerySchema } from '../../schemas/image'

const faqSchema = z.object({
  __typename: z.literal('ContentfulFaq'),
  title: z.string(),
  primary: z
    .object({
      question: z.object({
        question: z.string()
      }),
      answer: contentfulRichTextSchema
    })
    .array()
})

const layoutSchema = z.object({
  promoBannerStyle: z.string(),
  components: z
    .union([
      financingBannerSchema,
      affirmReviewGridSchema,
      financingSmallTextSchema,
      confidenceBannerSchema,
      packageCardFinancingGridSchema,
      faqSchema
    ])
    .array(),
  footer: z.object({
    contentful_id: z.string()
  })
})

export const financingPageSchema = z.object({
  contentful_id: z.string(),
  breadcrumbTitle: z.string(),
  slug: z.string(),
  layout: layoutSchema
})

export const parseFinancingPage = <
  T extends { readonly contentfulGenericLandingPage: U },
  U
>(
  query: T
) => {
  const financingPageFragment = query.contentfulGenericLandingPage

  const homeLandingPage = financingPageSchema.parse(financingPageFragment)

  return homeLandingPage
}

export type ContentfulImageQuerySchema = TypeOf<
  typeof contentfulImageQuerySchema
>
export type FinancingLayoutSchema = TypeOf<typeof layoutSchema>
