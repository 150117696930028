import Carousel from 'nuka-carousel'
import React, { useMemo } from 'react'

import { UserStoriesSchema } from './schema'
import { UserStory } from './UserStory'

const defaultControlConfig = {
  pagingDotsContainerClassName: '!top-10',
  prevButtonClassName: 'hidden',
  nextButtonClassName: 'hidden'
}

export type CarouselProps = {
  readonly userStories: React.ReactNode
  readonly className?: string
}

export function CarouselDesktop({
  userStories,
  className = ''
}: CarouselProps) {
  return (
    <div className={className} data-component="CarouselDesktop">
      <Carousel
        cellAlign="left"
        cellSpacing={20}
        defaultControlsConfig={defaultControlConfig}
        slidesToShow={4.2}
        wrapAround={true}
      >
        {userStories}
      </Carousel>
    </div>
  )
}

export function CarouselTablet({ userStories, className = '' }: CarouselProps) {
  return (
    <div className={className} data-component="CarouselTablet">
      <Carousel
        cellAlign="left"
        cellSpacing={20}
        defaultControlsConfig={defaultControlConfig}
        slidesToShow={3.2}
        wrapAround={true}
      >
        {userStories}
      </Carousel>
    </div>
  )
}

export function CarouselMobile({ userStories, className = '' }: CarouselProps) {
  return (
    <div className={className} data-component="CarouselMobile">
      <Carousel
        cellAlign="center"
        cellSpacing={10}
        defaultControlsConfig={defaultControlConfig}
        slidesToShow={1}
        wrapAround={true}
      >
        {userStories}
      </Carousel>
    </div>
  )
}

export function UserStories({ data }: { readonly data: UserStoriesSchema }) {
  const userStories = useMemo(
    () =>
      data.map((userStory, index) => (
        <UserStory key={index} userStory={userStory} />
      )),
    [data]
  )

  return (
    <div
      className="relative w-full pb-12 md:inset-x-1/2 md:ml-[-50vw] md:mr-[-50vw] md:w-screen md:max-w-[100vw] md:pb-8 md:pt-8"
      data-component="UserStories"
    >
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <h2 className="text-center">When SimpliSafe saved the day</h2>
        <CarouselMobile className="md:hidden" userStories={userStories} />
        <CarouselTablet
          className="hidden pl-16 md:block lg:hidden"
          userStories={userStories}
        />
        <CarouselDesktop
          className="hidden pl-16 lg:block"
          userStories={userStories}
        />
      </div>
    </div>
  )
}
