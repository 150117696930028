import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'
import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { FormikErrors } from 'formik'
import { trackContinueToPaymentClickError } from '@ecomm/cdp-tracking'

export function useTrackingShippingFormUserErrors() {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(
    (fields: FormikErrors<CheckoutFormValuesType>) => {
      trackEvent({
        event: 'continue-to-payment-click-failure',
        ...fields
      })
      trackContinueToPaymentClickError(fields)
    },
    [trackEvent]
  )
}
