import { gql } from '@apollo/client/index.js'

export const FOOTER_FRAGMENT = gql`
  fragment footer on Footer {
    legalLinksCollection {
      items {
        text
        url
        trackingEvent
      }
    }
    leadGenForm {
      ... on Form {
        title
        button {
          text
        }
        formCollection {
          items {
            placeholder
            type
            title
          }
        }
        note {
          json
        }
      }
    }
    copyrightText
    socialLinksCollection {
      items {
        text
        url
        icon {
          ...image
        }
      }
    }
    menusCollection {
      items {
        title
        titleUrl
        linksCollection {
          items {
            ... on Link {
              text
              url
            }
          }
        }
      }
    }
    disclaimerLink {
      ... on Link {
        text
        url
      }
    }
    type
  }
`
