import type { Options } from '@contentful/rich-text-react-renderer'
import type { Block, Inline } from '@contentful/rich-text-types'
import type { Links } from '@ecomm/contentful-schemas'
import { devThrowError } from '@ecomm/error-handling'
import { type Z, z } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import type React from 'react'

const dataSchema = z
  .object({
    target: z.object({
      sys: z.object({
        id: z.string()
      })
    })
  })
  .default({
    target: {
      sys: {
        id: ''
      }
    }
  })

export type CreateOptions = (links: Links) => Options['renderNode']

export const getNodeTargetId = (node: Block | Inline) =>
  dataSchema.parse(node.data).target.sys.id

export const getTargetAssetBlock = (links: Links, targetId: string) => {
  const target = links.assets?.block.find(({ id }) => id === targetId)
  if (!target) {
    devThrowError(
      Error(`RichText Error: Could not find target entry block ${targetId}`)
    )
    return
  } else {
    return target
  }
}

export const getTargetEntryBlock = (links: Links, targetId: string) => {
  const target = links.entries?.block.find(({ id }) => id === targetId)
  if (!target) {
    devThrowError(
      Error(`RichText Error: Could not find target entry block ${targetId}`)
    )
    return
  } else {
    return target
  }
}

export function renderBlocksEmbeddedEntry<T, U>(
  links: Links,
  node: Block | Inline,
  schema: Z.ZodSchema<T, Z.ZodTypeDef, U>,
  renderFn: (t: T) => React.JSX.Element
) {
  return pipe(
    getTargetEntryBlock(links, getNodeTargetId(node)),
    O.fromNullable,
    O.map(schema.parse),
    // this extra fromNullable is needed because the schema might return null/undefined
    O.chain(O.fromNullable),
    O.match(() => null, renderFn)
  )
}
