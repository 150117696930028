import { SSImg } from '@ecomm/ss-react-components'
import { Link } from 'gatsby'
import React, { ReactNode } from 'react'

export type CartWarningMessageProps = {
  readonly message: ReactNode
  readonly showIcon: boolean
  readonly iconSrc?: string
  readonly showChangePlanLink?: boolean
}

function CartWarningMessage({
  iconSrc,
  message,
  showIcon,
  showChangePlanLink
}: CartWarningMessageProps) {
  return (
    <div className="lg:py[60px] border-neutral-medium-100 mx-auto mb-[20px] max-w-[563px] border border-solid px-[25px] py-[15px] text-center md:mb-[32px] md:px-[60px] md:py-[30px] md:text-left">
      {/* TODO: Pull this image from content model */}
      {showIcon && iconSrc && (
        <SSImg
          alt="Icon - Caution (Grey)"
          className="mx-auto block"
          height={32}
          src={iconSrc}
          width={32}
        />
      )}
      <p className="text-center">{message}</p>
      {showChangePlanLink ? (
        <Link
          className="mx-auto block max-w-[127px] text-base  text-complementary-blue-100 underline hover:no-underline md:text-lg"
          to="/change-monitoring"
        >
          CHANGE PLAN
        </Link>
      ) : (
        <Link
          className="mx-auto block max-w-[99px] text-base  text-complementary-blue-100 underline hover:no-underline md:text-lg"
          to="/home-security-shop"
        >
          Shop now
        </Link>
      )}
    </div>
  )
}

export default CartWarningMessage
