import {
  baseApiUrl,
  locale as localeEnv,
  perimeterXEnabled
} from '@ecomm/data-env-variables'
import { safeFetchJson } from '@jvlk/fp-ts-fetch'
import { renderPerimeterXCaptcha } from '@simplisafe/ss-ecomm-data/perimeterX'
import { exists, window } from 'browser-monads-ts'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { FetchOptions, headersLens } from './fetch-options'
import { ErrorResponse } from './types'

const addUserAgent = headersLens.modify(prev =>
  exists(window) ? prev : { ...prev, 'User-Agent': 'SimpliSafe Frontend Node' }
)

const perimeterXElementId = 'px-captcha'
const isPerimeterXCaptchaPresent = () =>
  !!document.getElementById(perimeterXElementId)

function isError(res: Error | Response): res is Error {
  return res instanceof Error
}

/**
 * Exported for testing only. If given an error Response, normalizes it into
 * a form usable by downstream consumers. If passed an instance of an Error
 * (e.g. something went wrong parsing the json response in a previous step),
 * just return that Error.
 */
export function transformErrorResponse(
  response: Error | Response
): TE.TaskEither<Error, ErrorResponse> {
  return isError(response)
    ? TE.left(response)
    : TE.tryCatch(
        async () => {
          const responseBody = await response.text()

          const isPX =
            response.status === 403 && responseBody.includes('perimeterx')

          return {
            body: responseBody,
            isPerimeterXError: isPX,
            status: response.status,
            statusText: response.statusText,
            url: response.url
          }
        },
        e => Error(`Failed to parse response text: ${e}`)
      )
}

/**
 * Render the PerimeterX captcha if enabled, the response indicates a PX error,
 * and the captcha isn't already rendered on the page.
 */
function renderCaptcha(response: ErrorResponse) {
  const isPxEnabled = perimeterXEnabled() === 'true'
  const shouldRenderPX =
    isPxEnabled && response.isPerimeterXError && !isPerimeterXCaptchaPresent()

  shouldRenderPX && renderPerimeterXCaptcha(Error(response.body))
}

export function fetchApi(
  options: FetchOptions
): TE.TaskEither<Error | ErrorResponse, unknown> {
  return pipe(
    TE.Do,
    TE.bind('baseUrl', () => baseApiUrl),
    TE.bind('locale', () => localeEnv),
    TE.chain(({ baseUrl, locale }) => {
      const { method, headers, endpoint, body } = pipe(
        options,
        headersLens.modify(t => ({
          ...t,
          // these headers are added to all requests
          'Accept-Language': locale,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })),
        addUserAgent
      )
      return safeFetchJson(`${options.baseUrlOverride || baseUrl}${endpoint}`, {
        method,
        headers,
        body
      })
    }),
    TE.orElse(response =>
      pipe(
        transformErrorResponse(response),
        TE.map(res => {
          renderCaptcha(res)
          return res
        }),
        TE.chain((res): TE.TaskEither<Error | ErrorResponse, unknown> => {
          return TE.left(res)
        })
      )
    )
  )
}
