import { TypeOf, z } from '@simplisafe/ewok'

import { contentfulImageQuerySchema } from '../../../schemas/image'

export const financingBannerSchema = z.object({
  __typename: z.literal('ContentfulHeroBanner'),
  content: z
    .object({
      __typename: z.literal('ContentfulHeroBannerColumn'),
      logo: contentfulImageQuerySchema,
      description: z.object({
        raw: z.string(),
        references: z
          .object({
            contentful_id: z.string(),
            __typename: z.literal('ContentfulButton'),
            text: z.string(),
            url: z.string().nullish()
          })
          .array()
      }),
      image: contentfulImageQuerySchema
    })
    .array()
})

export type FinancingBannerSchema = TypeOf<typeof financingBannerSchema>
