import { JEBBIT_URL, JEBBIT_URL_UK } from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { GatsbyImage, QuoteWizardModal } from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/shared-hooks'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { useState } from 'react'

import { Item } from './Item'

export function NotFoundPage() {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query NotFoundPageQuery {
      HeroBannerAsset: contentfulAsset(
        contentful_id: { eq: "2yy8P7iz1lk1Bovnje8weG" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      MobileHeroBannerAsset: contentfulAsset(
        contentful_id: { eq: "65jJTduJkHGAp9o0yoIeqM" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      OurRecommendationAsset: contentfulAsset(
        contentful_id: { eq: "6sYXLIk3bQKRcuMvyA5edj" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      MobileOurRecommendationAsset: contentfulAsset(
        contentful_id: { eq: "6rBmlliCTHU9AT8B6xfng3" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      BuildSystemAsset: contentfulAsset(
        contentful_id: { eq: "5rLVCdFLXCvSZr3zZUVCDR" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      MobileBuildSystemAsset: contentfulAsset(
        contentful_id: { eq: "6ZQ6Ec4apIoh56eChMZrrC" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      ShopPackagesAsset: contentfulAsset(
        contentful_id: { eq: "4UTUV7acAPLQtZykS7war6" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      MobileShopPackagesAsset: contentfulAsset(
        contentful_id: { eq: "3VXzIS8KLkWftOPpwkWJSN" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
    }
  `)
  const locale = useLocale()
  const [modalOpen, setModalOpen] = useState(false)
  const isTabletAndUp = useMediaQuery('TabletAndUp')

  const getJebbitUrl = (locale: string): string =>
    locale === 'en-GB' ? JEBBIT_URL_UK : JEBBIT_URL

  const openQuoteWizardModal: React.MouseEventHandler<
    HTMLButtonElement
  > = event => {
    event.preventDefault()
    setModalOpen(true)
  }

  return (
    <div>
      <div className="lg:m-0">
        <div className="relative lg:m-auto lg:mt-7">
          <div className="absolute bottom-9 left-6 z-10 md:bottom-auto md:left-10 md:top-1/2 md:w-4/5 md:-translate-y-1/2 md:transform lg:left-16">
            <h1 className="m-0 text-4xl font-bold leading-[70px] text-white lg:text-[65px] lg:leading-none">
              Page not found
            </h1>
            <p className="m-0 mt-0 max-w-[247px] text-base text-white md:max-w-[281px] lg:mt-4 lg:max-w-sm lg:text-lg">
              Our cameras are great, but we didn’t find what you were looking
              for.
            </p>
            <Link
              className="bg-complementary-blue-100 mt-10 inline-block rounded-md px-6 py-3 text-center text-base text-white no-underline duration-200 ease-in-out lg:w-fit"
              to="/"
            >
              Go home
            </Link>
          </div>
          <GatsbyImage
            className="w-full lg:rounded-2xl"
            image={
              isTabletAndUp
                ? staticQuery.HeroBannerAsset
                : staticQuery.MobileHeroBannerAsset
            }
          />
        </div>
      </div>

      <div className="mx-4 mb-40 mt-16 flex flex-col gap-12 md:mx-10 lg:mx-0 lg:flex-row">
        <Item
          image={
            isTabletAndUp
              ? staticQuery.OurRecommendationAsset
              : staticQuery.MobileOurRecommendationAsset
          }
          linkText="Take our quiz"
          onClick={openQuoteWizardModal}
          text="Answer a few quick questions and we’ll recommend a system that meets your needs and budget."
          title="Get our recommendation"
        />
        <Item
          image={
            isTabletAndUp
              ? staticQuery.BuildSystemAsset
              : staticQuery.MobileBuildSystemAsset
          }
          linkText="Build my system"
          linkUrl="/build-my-system"
          text="Create your own custom home security system in minutes."
          title="Build my system"
        />
        <Item
          image={
            isTabletAndUp
              ? staticQuery.ShopPackagesAsset
              : staticQuery.MobileShopPackagesAsset
          }
          linkText="Shop packages"
          linkUrl="/home-security-shop-packages"
          text="Select a security system configured by our home security professionals."
          title="Shop packages"
        />
      </div>
      <QuoteWizardModal
        data={{
          __typename: 'ContentfulQuoteWizard',
          jebbitUrl: getJebbitUrl(locale),
          type: 'nav',
          id: '404-quote-wizard',
          nt_experiences: []
        }}
        onRequestClose={() => setModalOpen(false)}
        show={modalOpen}
      />
    </div>
  )
}
