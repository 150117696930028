import { addAtomDebugLabel, get as sessionStorageGet } from '@ecomm/utils'
import { window } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import * as R from 'fp-ts/lib/Record'
import { flow, pipe } from 'fp-ts/lib/function'
import * as io from 'io-ts'
import { useAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { useCallback, useMemo } from 'react'

const SESSION_STORAGE_FIRST_SEEN_URL = 'firstSeenUrl'

const storage = createJSONStorage(() => sessionStorage)

const getInitialValue = () => {
  const value = sessionStorageGet(SESSION_STORAGE_FIRST_SEEN_URL)
  return value ? JSON.parse(value) : null
}

const firstSeenAtom = atomWithStorage(
  SESSION_STORAGE_FIRST_SEEN_URL,
  getInitialValue(),
  storage
)
addAtomDebugLabel(firstSeenAtom, 'First Seen URL')

/**
 * Returns a get and set function for the users first seen url of this session.
 *
 * The value will only be set once per session.
 */
export const useFirstSeenUrl = (
  vid: string
): readonly [O.Option<string>, (url?: string) => void] => {
  const [_t, _setT] = useAtom(firstSeenAtom)

  const t = useMemo(() => {
    return pipe(
      O.fromNullable(_t),
      O.chain(R.lookup(vid)),
      O.chain(flow(io.string.decode, O.fromEither))
    )
  }, [_t, vid])

  const setT = useCallback(
    (url = window?.location?.pathname) => {
      O.isNone(t) && _setT({ [vid]: url })
    },
    [_setT, vid, t]
  )

  return [t, setT]
}
