import {
  ErrorResponse,
  createOrder as createApiOrder
} from '@ecomm/data-simplisafe-api'
import {
  COOKIE_DRUPAL_UID,
  COOKIE_FCP_ORDER_ID,
  setCookie
} from '@ecomm/shared-cookies'
import { pushToDataLayer } from '@ecomm/shared-window'
import { TrackMetricProps } from '@ecomm/tracking'
import { set as sessionStorageSet } from '@ecomm/utils'
import { voidFn } from '@simplisafe/ewok'
import { safePath } from '@simplisafe/monda'
import {
  CreateOrderRequest,
  CreateOrderV1Response as CreateOrderResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { handlePostPayment } from './postPurchaseHandling'
import {
  forwardToPaymentErrorUrl,
  getOrderId,
  logErrorWithOrderInfo
} from './utils/common'

export type AffirmOrderData = {
  readonly affirmCheckoutToken: string
  /** Type field is sent to the orders api endpoint. */
  readonly type: 'affirm'
}

export type ZuoraOrderData = {
  readonly paymentMethodId: string
  readonly token: string
  readonly type: 'credit'
  readonly provider: 'zuora'
}

export type OrderData = AffirmOrderData | ZuoraOrderData

const constructCreateOrderRequest = (
  cartId: string,
  orderData: OrderData
): CreateOrderRequest => ({
  cart: { id: cartId },
  custom: {
    fields:
      orderData.type === 'affirm'
        ? { paymentToken: orderData.affirmCheckoutToken }
        : // Order type is not Affirm, so it is Zuora
          { paymentMethodId: orderData.paymentMethodId }
  },
  type: orderData.type
})

/**
 * Affirms (get it?!) the chosen payment method has the correct data
 * NOTE: this does not throw, nor return, so no change happens except for error logging.
 */
const validateOrderData = (orderData: OrderData) => {
  orderData.type === 'affirm' &&
    !orderData.affirmCheckoutToken &&
    logErrorWithOrderInfo(Error('createOrder: missing affirmCheckoutToken'))

  orderData.type === 'credit' &&
    orderData.provider === 'zuora' &&
    !orderData.paymentMethodId &&
    logErrorWithOrderInfo(Error('createOrder: missing paymentMethodId'))
}

type CreateOrderProps = {
  readonly cartId: string
  readonly onPaymentComplete: () => void
  readonly onPaymentError: (_e: Error) => void
  readonly onPreactivationReady: (_webAppToken: string) => void
  readonly trackMetricEvent: TrackMetricProps
}

/**
 * Takes an Error | ErrorResponse and returns an Error substituting a generic
 * message for a given Response
 */
const remapError = (rsp: Error | ErrorResponse): Error =>
  rsp instanceof Error
    ? rsp
    : Error('createOrder: order response does not have a valid body')

/**
 * Stores uid off of order data for later retrieval
 */
const trackUid = (orderResponse: CreateOrderResponse) => {
  safePath(['custom', 'fields', 'uid'], orderResponse).forEach(uid => {
    setCookie(COOKIE_DRUPAL_UID, uid, {
      path: '/',
      sameSite: 'strict',
      secure: true
    })
  })
}

/**
 * Stores order id off of order data for later retrieval
 */
const trackOrderId = (orderResponse: CreateOrderResponse) => {
  const orderId = getOrderId(orderResponse)

  sessionStorageSet('orderId', orderId)

  // For Braze - we can use the ecomm cart data for purchase info,
  // but we need the order ID for the tag as well.
  const orderData = { orderID: orderId }
  pushToDataLayer(orderData)

  setCookie(COOKIE_FCP_ORDER_ID, orderId, {
    path: '/',
    sameSite: 'strict',
    secure: true
  })
}

/**
 * Wraps pulling bits off of order response and stashing them in storage
 */
const handleInternalTracking = (orderResponse: CreateOrderResponse) => {
  trackUid(orderResponse)
  trackOrderId(orderResponse)
}

/**
 * Creates an order in Commercetools, fires off post-payment handling, and
 * logs an error if bubbled up in the process.
 */
export const createOrder =
  (props: CreateOrderProps) =>
  async (orderData: OrderData): Promise<void> => {
    validateOrderData(orderData)

    const orderRequest = constructCreateOrderRequest(props.cartId, orderData)

    return pipe(
      createApiOrder(orderRequest),
      // consolidate the left side or process the right side
      TE.bimap(remapError, orderResponse => {
        handleInternalTracking(orderResponse)
        props.onPaymentComplete()
        return orderResponse
      }),
      // on success, chain into post-purchase handling
      TE.chain(orderResponse => {
        return TE.fromTask(
          handlePostPayment(
            orderResponse,
            props.onPreactivationReady,
            props.trackMetricEvent
          )
        )
      }),
      // handle errors, otherwise all done
      TE.match(error => {
        logErrorWithOrderInfo(error)
        props.onPaymentError(error)
        forwardToPaymentErrorUrl(error, props.trackMetricEvent)
      }, voidFn)
    )()
  }
