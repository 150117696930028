import {
  GatsbyImageSchema,
  createMockGatsbyImage
} from '@ecomm/contentful-schemas'

const mockGatsbyImageData: GatsbyImageSchema['gatsbyImageData'] = {
  height: 1,
  images: {
    fallback: {
      src: 'test-img.png'
    },
    sources: [
      {
        srcSet: 'test-img.png 436w',
        type: 'image/webp'
      }
    ]
  },
  layout: 'fullWidth',
  width: 1
}

export const headerSchemaMockData = {
  images: {
    nodes: [
      {
        contentful_id: '4ISQo32VbzFoCuz0sPSEIb',
        gatsbyImageData: mockGatsbyImageData,
        title: ''
      },
      {
        contentful_id: '3X477mLg2byYfBD0MzmdMe',
        gatsbyImageData: mockGatsbyImageData,
        title: ''
      },
      {
        contentful_id: '30qjwcrVYFr7ZLmfts9yID',
        gatsbyImageData: mockGatsbyImageData,
        title: ''
      },
      {
        contentful_id: '3',
        gatsbyImageData: mockGatsbyImageData,
        title: ''
      },
      {
        contentful_id: '4',
        gatsbyImageData: mockGatsbyImageData,
        title: ''
      }
    ]
  },
  quoteWizard: {
    contentful_id: '1L1QlxR38aLrLL718EWGUh',
    jebbitUrl: 'https://quiz.simplisafe.com/kaedkhe4?L=Full+Page&deferred=true',
    type: 'nav',
    __typename: 'ContentfulQuoteWizard',
    id: '6wRdF53o74w2MhOhBIkzip',
    nt_experiences: []
  },
  quoteWizardAsset: createMockGatsbyImage(
    '//images.ctfassets.net/v6awd0kabi65/1b21hSgtRAAbkJ8Ni53LHi/117a692de39068ce84a022a152fe2e8a/smoke_transparent_sq.png?w=400&h=360&q=50 1x,\n//images.ctfassets.net/v6awd0kabi65/1b21hSgtRAAbkJ8Ni53LHi/117a692de39068ce84a022a152fe2e8a/smoke_transparent_sq.png?w=600&h=540&q=50 1.5x,\n//images.ctfassets.net/v6awd0kabi65/1b21hSgtRAAbkJ8Ni53LHi/117a692de39068ce84a022a152fe2e8a/smoke_transparent_sq.png?w=800&h=720&q=50 2x,\n//images.ctfassets.net/v6awd0kabi65/1b21hSgtRAAbkJ8Ni53LHi/117a692de39068ce84a022a152fe2e8a/smoke_transparent_sq.png?w=1200&h=1080&q=50 3x'
  ),
  nestedHeroBannerData: {
    id: '123',
    title: 'Experiment Name',
    json: {
      id: '123',
      tag: 'control'
    },
    nt_experiences: []
  }
}

export const headerSchemaMockDataUK = {
  images: {
    nodes: [
      {
        contentful_id: '4ISQo32VbzFoCuz0sPSEIb',
        gatsbyImageData: mockGatsbyImageData,
        title: ''
      },
      {
        contentful_id: '3X477mLg2byYfBD0MzmdMe',
        gatsbyImageData: mockGatsbyImageData,
        title: ''
      },
      {
        contentful_id: '3',
        gatsbyImageData: mockGatsbyImageData,
        title: ''
      },
      {
        contentful_id: '4',
        gatsbyImageData: mockGatsbyImageData,
        title: ''
      }
    ]
  },
  quoteWizard: {
    contentful_id: '1L1QlxR38aLrLL718EWGUh',
    jebbitUrl: 'https://quiz.simplisafe.com/08odhevq?L=Full+Page&deferred=true',
    type: 'nav',
    __typename: 'ContentfulQuoteWizard',
    id: '6wRdF53o74w2MhOhBIkzip',
    nt_experiences: []
  }
}
