import { jsonSchema } from '@ecomm/micro-copy'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export function parseJSONDataFromContentful<T>(
  data: Record<string, unknown>,
  key: string
): T {
  return pipe(
    jsonSchema.parse(data[key]),
    parsedData => parsedData.content.internal.content,
    JSON.parse
  )
}

export function safeParseJSONDataFromContentful<T>(
  data: Record<string, unknown>,
  key: string
): O.Option<T> {
  return pipe(jsonSchema.safeParse(data[key]), parsedData =>
    parsedData.success
      ? O.of(JSON.parse(parsedData.data.content.internal.content))
      : O.none
  )
}
