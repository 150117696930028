import { BlogArticle } from '@ecomm/blog-components'
import {
  BLOG_ARTICLE_QUERY,
  blogArticleSchema,
  normalizeSlug
} from '@ecomm/blog-data'
import { Footer } from '@ecomm/footer-components'
import { Breadcrumbs, Header } from '@ecomm/header-redesigned'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { useContentful } from '@ecomm/shared-apollo'
import {
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import React, { Suspense } from 'react'

import type { SeoNodeSchema } from '../../config/blogPageResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useBlogLayout } from '../../hooks/useBlogLayout'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'

export type PageContext = {
  readonly slug: string
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly fbConfig: {
    readonly appId?: string
    readonly sdkUrl?: string
  }
}

type Props = {
  readonly pageContext: PageContext
  readonly path: string
}

export function Content({
  pageContext: { locale, seoDetails, fbConfig, slug }
}: Props) {
  const { promoBannerStyle, footer } = useBlogLayout()

  const bannerStyle = toPromoBannerStyleValue(promoBannerStyle) || 'none'

  const {
    canonicalLink,
    isNofollow,
    isNoindex,
    metaDescription,
    metaKeywords,
    metaTitle
  } = seoDetails

  const headerData = useHeaderRedesignQuery()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  const { data: rawData } = useContentful(BLOG_ARTICLE_QUERY, {
    slug: normalizeSlug(slug),
    site: locale === 'en-US' ? 'US' : 'UK'
  })

  const contentfulBlogPost = blogArticleSchema.parse(
    rawData?.blogCollection?.items[0],
    { path: ['BlogArticleTemplate', 'contentfulBlogPost'] }
  )

  const { title } = contentfulBlogPost

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink ?? ''}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords ?? []}
        metaTitle={metaTitle}
      />
      <PromoBannerWrapper
        experimentData={promoBannerExperiment}
        type={bannerStyle}
      />
      <Header {...headerData} />
      <Breadcrumbs
        steps={[
          {
            label: 'Blog',
            slug: 'blog'
          },
          {
            label: title,
            slug
          }
        ]}
      />
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        {locale === 'en-GB' && (
          <TrustpilotUKTopBanner className="mt-7 md:mt-8"></TrustpilotUKTopBanner>
        )}
        <BlogArticle {...contentfulBlogPost} facebookProps={fbConfig} />
      </div>
      {footer ? <Footer {...footer} type="Full" /> : null}
    </TrackingProvider>
  )
}

export default function BlogArticleTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}
