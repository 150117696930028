import { LEGAL_TEMPLATE_QUERY } from '@ecomm/legal-data'
import {
  CAMERA_BANNER_ASSET_QUERY,
  CONFIDENCE_BAR_QUERY,
  GUARANTEE_ASSET_QUERY,
  GUARANTEE_COPY_QUERY,
  VIDEO_BUTTON_ASSET_QUERY
} from '@ecomm/scout'
import { useContentful } from '@ecomm/shared-apollo'
import { Suspense } from 'react'

/**
 * Apollo queries to pre-load important data.
 *
 * THIS IS ONLY FOR GATSBY WHILE WE MIGRATE.
 *
 * This should include queries that are important across the site.
 * This is probably everything initially, and we can change it to be more selective as we make progress.
 */
function Queries() {
  // this is the main `/legal` page that can be reached from the footer
  // children pages are fetched on hovering over links
  useContentful(LEGAL_TEMPLATE_QUERY, {
    slug: 'legal'
  })

  useContentful(GUARANTEE_ASSET_QUERY)
  useContentful(GUARANTEE_COPY_QUERY)
  useContentful(CAMERA_BANNER_ASSET_QUERY)
  useContentful(VIDEO_BUTTON_ASSET_QUERY)
  useContentful(CONFIDENCE_BAR_QUERY, { id: '4MPjXZsXki3lB8IIfHPOau' })
  useContentful(CONFIDENCE_BAR_QUERY, { id: '5lTjX2gRqFH0isBGwGGXLH' })
  useContentful(CONFIDENCE_BAR_QUERY, { id: '2yJuhWpaW0nmKtDSEIEkxM' })
  useContentful(CONFIDENCE_BAR_QUERY, { id: '5ynnx5XFY9LYRUyZEkN98K' })

  return null
}

export function Prefetch() {
  return (
    <Suspense>
      <Queries />
    </Suspense>
  )
}
